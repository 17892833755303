import { graphqlClient } from "../../../client";
import mutationDocument from "./changeOrderApprovalStatus.graphql";
import type {
  Mutations,
  MutationsChangeOrderApprovalRequestStatusArgs,
  OpusInputChangeOrderApprovalRequestStatusType,
  OrderApprovalRequestType,
} from "@/core/api/graphql/types";

export async function changeOrderApprovalStatus(
  payload: OpusInputChangeOrderApprovalRequestStatusType,
): Promise<OrderApprovalRequestType> {
  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "changeOrderApprovalRequestStatus">>,
    MutationsChangeOrderApprovalRequestStatusArgs
  >({
    mutation: mutationDocument,
    variables: {
      command: payload,
    },
  });

  return data!.changeOrderApprovalRequestStatus;
}
