import { omit, uniqBy } from "lodash";
import { computed, readonly, ref, shallowRef } from "vue";
import { deleteMemberAddresses, getMyAddresses, updateMemberAddresses } from "@/core/api/graphql/account";
import { SortDirection } from "@/core/enums";
import { getSortingExpression, isEqualAddresses, Logger, toInputAddress } from "@/core/utilities";
import { useUser } from "./useUser";
import type {
  AddressValidationErrorType,
  InputMemberAddressType,
  OpusMemberAddressType,
} from "@/core/api/graphql/types";
import type { AnyAddressType, ISortInfo } from "@/core/types";
import type { ContactAddressesType } from "@/core/types/contact-addresses";

const loading = ref(false);
const contactAddresses = shallowRef<ContactAddressesType>();
const addresses = shallowRef<OpusMemberAddressType[]>([]);
const sort = ref<ISortInfo>({
  column: "isFavorite",
  direction: SortDirection.Descending,
});

export function useUserAddresses() {
  const { user } = useUser();

  function isExistAddress(address: AnyAddressType): boolean {
    return addresses.value.some((item) => isEqualAddresses(item, address));
  }

  async function fetchAddresses() {
    loading.value = true;

    const sortingExpression = getSortingExpression(sort.value);
    try {
      // OPUS
      contactAddresses.value = await getMyAddresses({ sort: "isFavorite:desc;line1:asc;city:asc", first: 100 });
      addresses.value = uniqBy(
        [...contactAddresses.value.shippingAddresses, ...contactAddresses.value.billingAddresses],
        "id",
      );
      // !OPUS
    } catch (e) {
      Logger.error(`${useUserAddresses.name}.${fetchAddresses.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function updateAddresses(items: OpusMemberAddressType[]): Promise<void> {
    loading.value = true;

    const inputAddresses: InputMemberAddressType[] = items.map(toInputAddress);

    const mutatedUpdatedAddresses: InputMemberAddressType[] = inputAddresses.map((address) =>
      omit(address, "isActive"),
    );

    try {
      await updateMemberAddresses(user.value.memberId!, mutatedUpdatedAddresses);
    } catch (e) {
      Logger.error(`${useUserAddresses.name}.${updateAddresses.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }

    await fetchAddresses();
  }

  async function addOrUpdateAddresses(items: OpusMemberAddressType[]): Promise<void> {
    if (!items.length) {
      return;
    }

    loading.value = true;

    const updatedAddresses: OpusMemberAddressType[] = addresses.value.slice();

    items.forEach((newAddress: OpusMemberAddressType) => {
      const index = updatedAddresses.findIndex((oldAddress) => oldAddress.id === newAddress.id);

      if (index === -1) {
        updatedAddresses.push(newAddress);
      } else {
        updatedAddresses.splice(index, 1, omit(newAddress));
      }
    });

    updatedAddresses.forEach((address: OpusMemberAddressType) => {
      return omit(address, "isActive");
    });
    const mutatedUpdatedAddresses: OpusMemberAddressType[] = updatedAddresses.map((address) => address);

    await updateAddresses(mutatedUpdatedAddresses);
  }

  async function removeAddresses(items: OpusMemberAddressType[]): Promise<void> {
    if (!items.length) {
      return;
    }

    loading.value = true;

    const inputAddresses: InputMemberAddressType[] = items.map(toInputAddress);

    try {
      await deleteMemberAddresses(inputAddresses, user.value.memberId!);
    } catch (e) {
      Logger.error(`${useUserAddresses.name}.${removeAddresses.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }

    await fetchAddresses();
  }

  function replaceAvalaraErrorsWithOpusErrors(error: AddressValidationErrorType): string {
    const errorCode = error.code?.endsWith(".") ? error.code.slice(0, -1) : error.code;

    const avalaraErrors: { [key: string]: string } = {
      "The address can't be found":
        "The address cannot be found. Please check spelling and make sure you are providing a full address.",
      "Directional or suffix information, such as 'west' or 'avenue', is wrong":
        "The address cannot be found. Please check spelling and make sure you are providing a full address.",
      "The street can't be found":
        "The street name cannot be found. Please check spelling and make sure you are providing a full address.",
      "The address number is out of range":
        "The address number cannot be found. Please check spelling and make sure you are providing a full address.",
      "The address has been standardized (meaning things like 'Post Office Box' were converted to 'PO Box'), but it still can't be validated for other reasons":
        "The address cannot be found. Please check spelling and make sure you are providing a full address.",
      "More than one address exists within the zip code you specified":
        "Multiple addresses found. Please provide a more specific zip code.",
      "An exact match for the street name can't be found":
        "The street name cannot be found. Please check spelling and make sure you are providing a full address.",
      "We're aware of the address, but it hasn't been added to the database just yet. Check with Avalara Support for an update":
        "We're aware of the address, but it hasn't been added to the database just yet. Check with Avalara Support for an update.",
      "The physical location of this address exists, but there aren't any homes on the street. This can happen near railroads or rivers where buildings can't be constructed":
        "The physical location of this address exists, but there aren't any homes on the street. This can happen near railroads or rivers where buildings can't be constructed",
      "Unknown country name": "The address cannot be found. Please check country name.",
      "Address is missing info, or the information entered is incorrect":
        "The address cannot be found. Please check spelling and make sure you are providing a full address.",
      "The correct tax jurisdiction can't be determined":
        "The address cannot be verified. Please provide a more specific zip code.",
      "The zip or postal code is wrong or missing numbers":
        "The address cannot be found. Please check zip or postal code.",
      "The address is not deliverable": "The address is not deliverable.",
      "Address not geocoded": "The address cannot be found.",
      "An exact street name match could not be found":
        "The street name cannot be found. Please check spelling and make sure you are providing a full address.",
      "The city could not be determined":
        "The city cannot be found. Please check spelling and make sure you are providing a full address.",
    };

    return errorCode ? avalaraErrors[errorCode] || error.message : error.message;
  }

  return {
    sort,
    isExistAddress,
    fetchAddresses,
    updateAddresses,
    addOrUpdateAddresses,
    removeAddresses,
    loading: readonly(loading),
    addresses: computed(() => addresses.value),
    contactAddresses: computed(() => contactAddresses.value),
    replaceAvalaraErrorsWithOpusErrors,
  };
}
