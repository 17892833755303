import { graphqlClient } from "../../../client";
import getSettingValuesQueryDocument from "./getSettingValues.graphql";
import type { Query, QuerySettingValuesArgs } from "@/core/api/graphql/types";

export async function getSettingValues(payload?: Partial<QuerySettingValuesArgs>): Promise<string[]> {
  const { data } = await graphqlClient.query<Required<Pick<Query, "settingValues">>, QuerySettingValuesArgs>({
    query: getSettingValuesQueryDocument,
    variables: {
      ...payload,
    },
  });

  return data.settingValues.items || [];
}
