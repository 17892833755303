import { OpusPermissions } from "@/core/enums";
import { useUser } from "@/shared/account";
import type { RouteRecordRaw } from "vue-router";

const Dashboard = () => import("@/pages/account/dashboard.vue");
const Profile = () => import("@/pages/account/profile.vue");
const ChangePassword = () => import("@/pages/account/change-password.vue");
const Addresses = () => import("@/pages/account/addresses.vue");
const Orders = () => import("@/pages/account/orders.vue");
const OrderDetails = () => import("@/pages/account/order-details.vue");
const OrderPayment = () => import("@/pages/account/order-payment.vue");
const Lists = () => import("@/pages/account/lists.vue");
const ListDetails = () => import("@/pages/account/list-details.vue");
const CheckoutDefaults = () => import("@/pages/account/checkout-defaults.vue");
const SavedCreditCards = () => import("@/pages/account/saved-credit-cards.vue");
const Impersonate = () => import("@/pages/account/impersonate.vue");
// OPUS
const OpOrdersForApproval = () => import("@/pages/account/op-orders-for-approval.vue");
const OpQuickConnectHistory = () => import("@/pages/opus/op-quick-connect-history.vue");
const OpDocStorage = () => import("@/pages/account/op-doc-storage.vue");
const OpDocDetails = () => import("@/pages/account/op-doc-details.vue");
const OpOrderDetailsShall = () => import("@/pages/opus/op-order-details-shell.vue");
// !OPUS

export const accountRoutes: RouteRecordRaw[] = [
  // OPUS
  // { path: "dashboard", name: "Dashboard", component: Dashboard },
  // OPUS
  { path: "profile", name: "Profile", component: Profile },
  { path: "change-password", name: "ChangePasswordAccount", component: ChangePassword },
  {
    path: "addresses",
    name: "Addresses",
    component: Addresses,
    beforeEnter(_to, _from, next) {
      const { isCorporateMember } = useUser();

      if (!isCorporateMember.value) {
        next();
      } else {
        next({ name: "Dashboard" });
      }
    },
  },
  {
    path: "orders",
    children: [
      { path: "", name: "Orders", component: Orders },
      {
        path: ":orderId",
        children: [
          {
            path: "",
            name: "OrderDetails",
            component: OrderDetails,
            props: true,
          },
          {
            path: "payment",
            name: "OrderPayment",
            component: OrderPayment,
            props: true,
            meta: { layout: "Secure" },
          },
        ],
        meta: { hideLeftSidebar: true },
      },
    ],
  },
  {
    path: "lists",
    children: [
      { path: "", name: "Lists", component: Lists },
      {
        path: ":listId",
        name: "ListDetails",
        component: ListDetails,
        props: true,
      },
    ],
  },
  {
    path: "orders-for-approval",
    children: [
      { path: "", name: "OpOrdersForApproval", component: OpOrdersForApproval },
      {
        path: ":orderId",
        name: "OpApprovalOrderDetails",
        component: OpOrderDetailsShall,
        props: true,
        meta: { hideLeftSidebar: true },
      },
      {
        path: "supplier-order/:orderId",
        name: "OpApprovalSupplierOrderDetails",
        component: OrderDetails,
        props: true,
        meta: { hideLeftSidebar: true },
      },
    ],
    beforeEnter(to, from, next) {
      const { checkPermissions } = useUser();

      if (checkPermissions(OpusPermissions.CanApproveOrders)) {
        next();
      } else {
        next({ name: "Orders", replace: true });
      }
    },
  },
  {
    path: "quick-connect-history",
    meta: { requiresAuth: true },
    name: "OpQuickConnectHistory",
    component: OpQuickConnectHistory,
  },
  {
    path: "doc-storage",
    children: [
      { path: "", name: "OpDocStorage", component: OpDocStorage },
      {
        path: ":taxCertificateId",
        children: [
          {
            path: "",
            name: "OpDocDetails",
            component: OpDocDetails,
            props: true,
          },
        ],
      },
    ],
  },
  // !OPUS
  { path: "checkout-defaults", name: "CheckoutDefaults", component: CheckoutDefaults },
  {
    path: "saved-credit-cards",
    name: "SavedCreditCards",
    component: SavedCreditCards,
  },
  {
    path: "impersonate/:userId",
    name: "Impersonate",
    props: true,
    component: Impersonate,
  },
];
