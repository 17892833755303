import { useQuery } from "@vue/apollo-composable";
import { GetUsaRegionsDocument } from "@/core/api/graphql/types";

export function getUsaRegions() {
  const usaCountryId = "usa";

  return useQuery(
    GetUsaRegionsDocument,
    { countryId: usaCountryId },
    {
      fetchPolicy: "cache-first",
      nextFetchPolicy: "cache-first",
    },
  );
}
