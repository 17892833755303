import { graphqlClient } from "../../../../client";
import toggleFavoriteSupplierMutation from "./toggleFavoriteSupplierMutation.graphql";
import type { Mutations, MutationsToggleFavoriteSupplierArgs } from "@/core/api/graphql/types";

export async function toggleFavoriteSupplier(supplierOuterId: string): Promise<void> {
  await graphqlClient.mutate<Mutations, MutationsToggleFavoriteSupplierArgs>({
    mutation: toggleFavoriteSupplierMutation,
    variables: {
      command: {
        supplierOuterId,
      },
    },
  });
}
