import type { OpusContactType, SupplierType } from "@/core/api/graphql/types";

export enum OpusApproveOrderModalCondition {
  Approve = "approve",
  Reject = "reject",
}

export enum OpusApprovalOrderStatuses {
  Approved = "Approved",
  Rejected = "Rejected",
  ApprovalNeeded = "Approval needed",
}

export type TermsAndConditionsBlockType = {
  hidden: boolean;
  opened: boolean;
  content: {
    id: number;
    title: string;
    text: string;
  };
  isAccepted: boolean;
};

export type OpOrganizationOrdersFilterDataType = {
  startDate?: string;
  endDate?: string;
  suppliers: SupplierType[];
  buyers: OpusContactType[];
  statuses: string[];
  paymentType: PaymentFilterType[];
};

export type OpOrganizationOrdersFilterChipsItemType = {
  fieldName: keyof OpOrganizationOrdersFilterDataType;
  value?: string;
  label: string;
};

export type PaymentFilterType = {
  text: string;
  value: string;
};

export type SettingsCatalogFacetType = {
  key: string;
  value: string;
};
