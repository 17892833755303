import { graphqlClient } from "../../../client";
import getMyAddressesQueryDocument from "./getMyAddressesQuery.graphql";
import type { OpusContactTypeAddressesArgs, Query } from "@/core/api/graphql/types";
import type { ContactAddressesType } from "@/core/types/contact-addresses";

export async function getMyAddresses(payload?: OpusContactTypeAddressesArgs): Promise<ContactAddressesType> {
  const { data } = await graphqlClient.query<Required<Pick<Query, "me">>, OpusContactTypeAddressesArgs>({
    query: getMyAddressesQueryDocument,
    variables: {
      ...payload,
    },
  });

  const billingAddresses = data.me.contact?.billingAddresses?.items || [];
  const shippingAddresses = data.me.contact?.shippingAddresses?.items || [];

  return { billingAddresses: billingAddresses, shippingAddresses: shippingAddresses };
}
