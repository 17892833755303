import { computed, readonly, ref, shallowRef } from "vue";
import { getSearchResults } from "@/core/api/graphql";
import { Logger } from "@/core/utilities";
import type { GetSearchResultsParamsType } from "@/core/api/graphql";
import type { Category, Product, SupplierType } from "@/core/api/graphql/types";

const loading = ref(false);
const categories = shallowRef<Category[]>([]);
const products = ref<Product[]>([]);
const suppliers = shallowRef<SupplierType[]>([]);
const suppliersTotal = ref(0);

export function useSearchResultPage() {
  async function searchResults(params: GetSearchResultsParamsType, showAllCategories?: boolean) {
    const preparedParams: GetSearchResultsParamsType = {
      ...params,
      keyword: params.keyword,
      suppliers: {
        itemsPerPage: 12,
        isActive: true,
      },
    };

    loading.value = true;

    try {
      const {
        categories: { items: categoriesItems = [] },
        products: { items: productsItems = [], totalCount = 0 },
        suppliers: { items: suppliersItems = [], totalCount: suppliersTotalCount = 0 },
      } = await getSearchResults(preparedParams);

      categories.value = categoriesItems;
      suppliers.value = suppliersItems;
      suppliersTotal.value = suppliersTotalCount;
    } catch (e) {
      Logger.error(`useSearchResultPage.${searchResults.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function loadAllCategories(params: GetSearchResultsParamsType, showAllCategories?: boolean) {
    const preparedParams: GetSearchResultsParamsType = {
      ...params,
      keyword: params.keyword,
      products: {
        itemsPerPage: 0,
      },
    };

    loading.value = true;

    try {
      const {
        categories: { items: categoriesItems = [] },
      } = await getSearchResults(preparedParams);

      categories.value = categoriesItems;
    } catch (e) {
      Logger.error(`useSearchResultPage.${loadAllCategories.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    searchResults,
    loadAllCategories,
    loading: readonly(loading),
    categories: computed(() => categories.value),
    products: computed(() => products.value),
    suppliers: computed(() => suppliers.value),
    suppliersTotal: readonly(suppliersTotal),
  };
}
