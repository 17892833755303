import { ref } from "vue";
import type { OpusUserType, Organization } from "@/core/api/graphql/types";

interface IChameleonUserData {
  uid: string;
  email?: string;
  name?: string;
  created?: string;
  company?: {
    uid?: string;
    name?: string;
  };
}

interface IChameleon {
  identify: (uid: string, userData: IChameleonUserData) => void;
}

declare global {
  interface Window {
    chmln?: IChameleon;
  }
}

const isInitialized = ref(false);

export function useChameleon() {
  const getUserData = (user: OpusUserType, organization: Organization | null): IChameleonUserData => ({
    uid: user.id,
    email: user.email,
    name: `${user.contact?.firstName} ${user.contact?.lastName}`,
    created: localStorage.getItem("op-sign-up-date") ?? undefined,
    company: organization
      ? {
          uid: organization.outerId,
          name: organization.name,
        }
      : undefined,
  });

  const waitForPageLoad = (): Promise<void> => {
    return new Promise((resolve) => {
      if (document.readyState === "complete") {
        resolve();
      } else {
        window.addEventListener("load", () => resolve());
      }
    });
  };

  const identifyChameleonUser = async (user: OpusUserType, organization: Organization | null): Promise<void> => {
    if (isInitialized.value) {
      return;
    }

    try {
      await waitForPageLoad();

      return new Promise<void>((resolve) => {
        if (window.chmln?.identify) {
          window.chmln.identify(user.id, getUserData(user, organization));
          isInitialized.value = true;
          resolve();
        } else {
          const timeoutId = setTimeout(() => {
            if (window.chmln?.identify) {
              window.chmln.identify(user.id, getUserData(user, organization));
              isInitialized.value = true;
            }
            resolve();
          }, 5000);

          return () => clearTimeout(timeoutId);
        }
      });
    } catch (e) {
      console.warn("Chameleon initialization failed:", e);
    }
  };

  return {
    identifyChameleonUser,
  };
}
