import { graphqlClient } from "../../../client";
import mutationDocument from "./createOrderFromCartMutation.graphql";
import type { MutationsCreateOrderFromCartArgs, CreateOrderFromCartMutation } from "@/core/api/graphql/types";

export async function createOrderFromCart(
  cartId?: string,
  withProperties = false,
): Promise<CreateOrderFromCartMutation["createOrderFromCart"]> {
  const { data } = await graphqlClient.mutate<
    CreateOrderFromCartMutation,
    MutationsCreateOrderFromCartArgs & { withProperties: boolean }
  >({
    mutation: mutationDocument,
    variables: {
      command: {
        cartId,
      },
      withProperties,
    },
  });

  return data?.createOrderFromCart;
}
