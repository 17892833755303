import { omit } from "lodash";
import { computed, readonly, ref, shallowRef, unref } from "vue";
import {
  addAddressToFavorites,
  deleteMemberAddresses,
  removeAddressFromFavorites,
  updateMemberAddresses,
  deactivateMemberAddress,
  activateMemberAddress,
} from "@/core/api/graphql/account";
import { getOrganizationAddresses } from "@/core/api/graphql/organization";
import { SortDirection, AddressType } from "@/core/enums";
import { getSortingExpression, Logger, toInputAddress } from "@/core/utilities";
import type { InputMemberAddressType, OpusMemberAddressType } from "@/core/api/graphql/types";
import type { ISortInfo } from "@/core/types";
import type { MaybeRef } from "@vueuse/core";

const requestedAddressesQuantity = 9999;

const loading = ref(false);
const addresses = shallowRef<OpusMemberAddressType[]>([]);
const sort = ref<ISortInfo>({
  column: "isFavorite",
  direction: SortDirection.Descending,
});

export function useOrganizationAddresses(organizationId: MaybeRef<string>) {
  async function fetchAddresses() {
    try {
      loading.value = true;

      const sortingExpression = getSortingExpression(sort.value);
      // OPUS
      const preparedSortingExpression = prepareSortExtension(sortingExpression);
      // !OPUS
      const { items = [] } = await getOrganizationAddresses(unref(organizationId), {
        sort: preparedSortingExpression,
        first: requestedAddressesQuantity,
      });

      addresses.value = items.filter((item) => item.addressType != AddressType.Billing);
    } catch (e) {
      Logger.error(`${useOrganizationAddresses.name}.${fetchAddresses.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function removeAddresses(items: OpusMemberAddressType[]): Promise<void> {
    loading.value = true;

    const inputAddresses: InputMemberAddressType[] = items.map(toInputAddress);

    try {
      await deleteMemberAddresses(inputAddresses, unref(organizationId));
    } catch (e) {
      Logger.error(`${useOrganizationAddresses.name}.${removeAddresses.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }

    await fetchAddresses();
  }

  async function updateAddresses(items: OpusMemberAddressType[]): Promise<void> {
    loading.value = true;

    const inputAddresses: InputMemberAddressType[] = items.map(toInputAddress);
    const mutatedUpdatedAddresses: InputMemberAddressType[] = inputAddresses.map((address) =>
      omit(address, "isActive"),
    );

    try {
      await updateMemberAddresses(unref(organizationId), mutatedUpdatedAddresses);
    } catch (e) {
      Logger.error("useUserAddresses.updateAddresses", e);
      throw e;
    } finally {
      loading.value = false;
    }

    await fetchAddresses();
  }

  async function addOrUpdateAddresses(items: OpusMemberAddressType[]): Promise<void> {
    if (!items.length) {
      return;
    }

    const updatedAddresses: OpusMemberAddressType[] = addresses.value.slice();

    items.forEach((newAddress: OpusMemberAddressType) => {
      const index = updatedAddresses.findIndex((oldAddress) => oldAddress.id === newAddress.id);
      if (index === -1) {
        updatedAddresses.push(newAddress);
      } else {
        updatedAddresses.splice(index, 1, newAddress);
      }
    });

    const mutatedUpdatedAddresses: OpusMemberAddressType[] = updatedAddresses.map((address) => address);

    await updateAddresses(mutatedUpdatedAddresses);
  }

  async function deactivateAddress(item: OpusMemberAddressType): Promise<void> {
    if (item && item.id) {
      try {
        await deactivateMemberAddress({ addressKey: item.id!, memberId: unref(organizationId) });
      } catch (e) {
        Logger.error("useOrganizationAddresses.deactivateAddress", e);
        throw e;
      } finally {
        loading.value = false;
      }

      await fetchAddresses();
    }
  }

  async function activateAddress(item: OpusMemberAddressType): Promise<void> {
    if (item && item.id) {
      try {
        await activateMemberAddress({ addressKey: item.id!, memberId: unref(organizationId) });
      } catch (e) {
        Logger.error("useOrganizationAddresses.activateAddress", e);
        throw e;
      } finally {
        loading.value = false;
      }

      await fetchAddresses();
    }
  }

  async function addAddressToFavorite(addressId: string): Promise<void> {
    loading.value = true;

    try {
      await addAddressToFavorites(addressId);
    } catch (e) {
      Logger.error(`${useOrganizationAddresses.name}.${addAddressToFavorite.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }

    await fetchAddresses();
  }

  async function removeAddressFromFavorite(addressId: string): Promise<void> {
    loading.value = true;

    try {
      await removeAddressFromFavorites(addressId);
    } catch (e) {
      Logger.error(`${useOrganizationAddresses.name}.${removeAddressFromFavorites.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }

    await fetchAddresses();
  }

  return {
    sort,
    fetchAddresses,
    removeAddresses,
    addOrUpdateAddresses,
    activateAddress,
    deactivateAddress,
    addAddressToFavorite,
    removeAddressFromFavorite,
    loading: readonly(loading),
    addresses: computed(() => addresses.value),
  };
}

function prepareSortExtension(sortingExpression: string): string {
  let editedSortingExpression = sortingExpression;
  if (!sortingExpression.includes("isFavorite")) {
    editedSortingExpression = "isFavorite:desc;" + editedSortingExpression;
  }
  if (sortingExpression.includes("line1:asc")) {
    editedSortingExpression = editedSortingExpression + ";city:asc;";
  }
  if (sortingExpression.includes("line1:desc")) {
    editedSortingExpression = editedSortingExpression + ";city:desc;";
  }

  return editedSortingExpression;
}
