import { graphqlClient } from "../../../client";
import getOrdersQueryDocument from "./getOrdersForApprovalQuery.graphql";
import type { OrderApprovalRequestConnection, Query, QueryOrderApprovalRequestsArgs } from "@/core/api/graphql/types";

export async function getOrdersForApproval(
  payload: QueryOrderApprovalRequestsArgs,
): Promise<OrderApprovalRequestConnection> {
  const { data } = await graphqlClient.query<
    Required<Pick<Query, "orderApprovalRequests">>,
    QueryOrderApprovalRequestsArgs
  >({
    query: getOrdersQueryDocument,
    variables: {
      ...payload,
    },
  });

  return data.orderApprovalRequests;
}
