<template>
  <ProductTitledBlock
    v-if="distributorData"
    :title="model.title || $t('shared.catalog.product_details.op_distributor.title')"
    icon="truck"
  >
    <VcCollapsibleContent max-height="12.5rem">
      <div class="space-y-4">
        <VcProperty :label="distributorData.title" class="items-end text-base">
          <VcImage :alt="distributorData.title" class="h-[26px]" :src="distributorImageSrc" />
        </VcProperty>
      </div>
    </VcCollapsibleContent>
  </ProductTitledBlock>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { OP_DISTRIBUTOR_NAME_BY_VISIBILITY_CODE } from "@/core/constants/opus";
import { ProductTitledBlock } from "@/shared/catalog";
import type { Product } from "@/core/api/graphql/types";

interface IProps {
  product: Product;
  model: {
    title?: string;
    hidden?: boolean;
  };
}

const props = defineProps<IProps>();

const permittedSupplierOuterIds = ["275"];

const distributorData = computed<{ title: string; imgName: string } | undefined>(() => {
  const outerId = props.product.vendor?.supplier?.outerId;
  const productVisibilityCode = props.product.vendor?.supplier?.productVisibilityCode;
  if (
    outerId &&
    productVisibilityCode &&
    permittedSupplierOuterIds.includes(outerId) &&
    productVisibilityCode in OP_DISTRIBUTOR_NAME_BY_VISIBILITY_CODE
  ) {
    return OP_DISTRIBUTOR_NAME_BY_VISIBILITY_CODE[productVisibilityCode];
  } else {
    return undefined;
  }
});

const distributorImageSrc = computed(() => {
  if (distributorData.value?.imgName) {
    return `${"/static/images/opus/distributor-logos/" + distributorData.value?.imgName}`;
  } else {
    return "";
  }
});
</script>
