import { graphqlClient } from "@/core/api/graphql/client";
import { globals } from "@/core/globals";
import setShippingLocationMutation from "./setShippingLocationMutation.graphql";
import type { Mutations, MutationsSetShippingLocationArgs } from "@/core/api/graphql/types";

export async function setShippingLocation(locationAddressId: string): Promise<boolean> {
  const { storeId, userId, cultureName, currencyCode } = globals;

  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "setShippingLocation">>,
    MutationsSetShippingLocationArgs
  >({
    mutation: setShippingLocationMutation,
    variables: {
      command: {
        userId,
        storeId,
        currencyCode,
        locationAddressId,
        cultureName,
      },
    },
  });

  return data!.setShippingLocation;
}
