import { computed, readonly, ref, shallowRef } from "vue";
import { getCategories } from "../api/graphql/opus/catalog";
import { globals } from "../globals";
import { Logger } from "../utilities";
import type { Category } from "@/core/api/graphql/types";

const loading = ref(true);
const childCategories = shallowRef<Category[]>([]);

export function useCategories() {
  async function fetchCategories(): Promise<void> {
    loading.value = true;

    try {
      const { catalogId } = globals;
      const { items } = await getCategories({
        filter: `parent:${catalogId}`,
        sort: "priority;name",
        first: 100,
      });
      childCategories.value = items || [];
    } catch (e) {
      Logger.error(`${useCategories.name}.${fetchCategories.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    fetchCategories,
    loading: readonly(loading),
    childCategories: computed(() => childCategories.value),
  };
}
