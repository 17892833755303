export enum QueryParamName {
  Page = "page",
  Sort = "sort",
  Facets = "facets",
  Keyword = "keyword",
  ItemsPerPage = "size",
  SearchPhrase = "q",
  SubSource = "utm_campaign",
  CreatedWay = "utm_source",
}
