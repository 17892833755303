import { graphqlClient } from "../../../client";
import mutationDocument from "./activateMemberAddressMutation.graphql";
import type {
  InputActivateMemberAddressType,
  Mutations,
  MutationsActivateMemberAddressArgs,
} from "@/core/api/graphql/types";

export async function activateMemberAddress(payload: InputActivateMemberAddressType): Promise<void> {
  await graphqlClient.mutate<Required<Pick<Mutations, "activateMemberAddress">>, MutationsActivateMemberAddressArgs>({
    mutation: mutationDocument,
    variables: {
      command: {
        addressKey: payload.addressKey,
        memberId: payload.memberId,
      },
    },
  });
}
