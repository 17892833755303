import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import mutationDocument from "./cloneWishlistMutation.graphql";
import type { Mutations, MutationsCloneWishlistArgs, WishlistType } from "@/core/api/graphql/types";
import type { CloneWishlistPayloadType } from "@/core/types/clone-wishlist-payload";

export async function cloneWishlist(payload: CloneWishlistPayloadType): Promise<WishlistType> {
  const { storeId, userId, cultureName, currencyCode } = globals;

  const { data } = await graphqlClient.mutate<Required<Pick<Mutations, "cloneWishlist">>, MutationsCloneWishlistArgs>({
    mutation: mutationDocument,
    variables: {
      command: {
        storeId,
        userId,
        cultureName,
        currencyCode,
        ...payload,
      },
    },
  });

  return data!.cloneWishlist;
}
