import { graphqlClient } from "@/core/api/graphql/client";
import mutationDocument from "./createQuoteRequestsMutation.graphql";
import type {
  CreateQuoteRequestsResultType,
  InputCreateQuoteRequestsType,
  Mutations,
  MutationsCreateQuoteRequestsArgs,
} from "@/core/api/graphql/types";

export async function createQuoteRequests(
  payload: InputCreateQuoteRequestsType,
): Promise<CreateQuoteRequestsResultType> {
  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "createQuoteRequests">>,
    MutationsCreateQuoteRequestsArgs
  >({
    mutation: mutationDocument,
    variables: {
      command: {
        ...payload,
      },
    },
  });

  return data!.createQuoteRequests;
}
