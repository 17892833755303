import { computed, ref, unref } from "vue";
import { updateMemberDynamicProperties } from "@/core/api/graphql";
import { Logger } from "@/core/utilities";
import { useUser } from "@/shared/account";
import { pageReloadEvent, TabsType, useBroadcast } from "@/shared/broadcast";
import { TERMS_RELEASE_DATE } from "../constants";
import { getDateWithoutTime } from "../utils";
import type { ComputedRef } from "vue";

export function useTermsAndConditions() {
  const loading = ref(false);

  const broadcast = useBroadcast();
  const { user, organization, fetchUser } = useUser();

  const termsAcceptanceDate = computed(
    () =>
      user?.value?.contact?.dynamicProperties.find((x) => x.name === "TermAndConditionsAcceptDate")?.value as string,
  );

  const isTermsAccepted = computed(() => {
    const acceptDate = user?.value?.contact?.dynamicProperties.find((x) => x.name === "TermAndConditionsAcceptDate")
      ?.value as string;

    if (acceptDate) {
      const formattedCurrentDate = getDateWithoutTime(new Date());
      const termsReleaseDate = TERMS_RELEASE_DATE.split("T")[0];
      const formattedAcceptDate = getDateWithoutTime(new Date(acceptDate));

      if (formattedCurrentDate >= termsReleaseDate && formattedAcceptDate < termsReleaseDate) {
        return false;
      }
    }

    return !!acceptDate;
  });

  const canBypassTermsAndConditionsSection1 = computed(() => {
    const bypassByUser = user?.value?.contact?.dynamicProperties.find(
      (x) => x.name === "CanBypassTermsAndConditionsSection1",
    );
    const bypassByOrganization = organization?.value?.dynamicProperties.find(
      (x) => x.name === "CanBypassTermsAndConditionsSection1",
    );
    return !!((bypassByUser && bypassByUser.value) || (bypassByOrganization && bypassByOrganization.value));
  });
  const canBypassTermsAndConditionsSection2 = computed(() => {
    const bypassByUser = user?.value?.contact?.dynamicProperties.find(
      (x) => x.name === "CanBypassTermsAndConditionsSection2",
    );
    const bypassByOrganization = organization?.value?.dynamicProperties.find(
      (x) => x.name === "CanBypassTermsAndConditionsSection2",
    );
    return !!((bypassByUser && bypassByUser.value) || (bypassByOrganization && bypassByOrganization.value));
  });
  const autoAcceptTerms = computed(
    () => canBypassTermsAndConditionsSection1.value && canBypassTermsAndConditionsSection2.value,
  );

  const acceptedSections = computed(() => {
    const dynProp = user?.value?.contact?.dynamicProperties.find((x) => x.name === "TermAndConditionsAcceptSections");
    return dynProp?.value;
  });

  async function acceptTerms(): Promise<void> {
    try {
      loading.value = true;
      const dynamicProperties = [{ name: "TermAndConditionsAcceptDate", value: new Date().toISOString() }];
      const getAcceptSection = (canBypassSection: ComputedRef<boolean>) => (!canBypassSection.value ? "Yes" : "No");
      const acceptSections = `Section1:${getAcceptSection(
        canBypassTermsAndConditionsSection1,
      )};Section2:${getAcceptSection(canBypassTermsAndConditionsSection2)};`;
      dynamicProperties.push({ name: "TermAndConditionsAcceptSections", value: acceptSections });

      await updateMemberDynamicProperties({
        memberId: user.value!.memberId!,
        dynamicProperties: dynamicProperties,
      });
      await fetchUser();
      void broadcast.emit(pageReloadEvent, null, TabsType.OTHERS);
    } catch (e) {
      Logger.error(`${useTermsAndConditions.name}`, e);
    }
    loading.value = false;
  }

  return {
    loading,
    isTermsAccepted,
    acceptedSections,
    termsAcceptanceDate,
    canBypassTermsAndConditionsSection1,
    canBypassTermsAndConditionsSection2,
    autoAcceptTerms,
    acceptTerms,
  };
}
