import { createRouter as _createRouter, createWebHistory } from "vue-router";
import { useAppInsights } from "vue3-application-insights";
import { useThemeContext, useAuth } from "@/core/composables";
import { getReturnUrlValue } from "@/core/utilities";
import { useUser } from "@/shared/account";
import { useTermsAndConditions } from "@/shared/opus";
import { mainRoutes } from "./routes";
import type { RouteRecordName } from "vue-router";

export function createRouter(options: { base: string }) {
  const { base } = options;
  const { isAuthenticated, organization, user, fetchUser } = useUser();
  const { unauthorize } = useAuth();

  const { themeContext } = useThemeContext();

  // OPUS
  const { isTermsAccepted } = useTermsAndConditions();
  // !OPUS

  const router = _createRouter({
    routes: mainRoutes,
    history: createWebHistory(base),
    scrollBehavior(to, from, savedPosition) {
      if (to.path !== from.path) {
        return savedPosition || { top: 0 };
      } else {
        return undefined;
      }
    },
  });

  router.beforeEach(async (to, _from, next) => {
    // OPUS
    const appInsights = useAppInsights();
    if (appInsights) {
      appInsights.trackPageView({
        name: to.name as string,
        uri: to.path,
      });
    }
    // !OPUS

    if (isAuthenticated.value && Array<RouteRecordName>("ResetPassword", "OpRegistrationWelcome").includes(to.name!)) {
      void unauthorize();
      await fetchUser();
      window.location.href = to.fullPath;
      return to.fullPath;
    }

    if (
      isAuthenticated.value &&
      user.value.passwordExpired &&
      user.value.temporaryPassword &&
      to.name !== "TemporaryPass"
    ) {
      return next({
        name: "TemporaryPass",
      });
    }

    if (
      isAuthenticated.value &&
      !isTermsAccepted.value &&
      to.name !== "TermsAndConditions" &&
      !user.value.forcePasswordChange
    ) {
      return next({
        name: "TermsAndConditions",
      });
    }

    // Protecting routes
    const unauthorizedAccessIsDenied: boolean =
      !isAuthenticated.value &&
      !to.meta.public &&
      (to.meta.requiresAuth || !themeContext.value.storeSettings.anonymousUsersAllowed);

    if (unauthorizedAccessIsDenied) {
      return next({
        name: "SignIn",
        // save the location we were at to come back later
        query: { returnUrl: to.fullPath },
      });
    }

    // Protecting company routes
    if (to.meta.requiresOrganization && !organization.value) {
      return next({ name: "Account" });
    }

    // Make Dashboard the default Home page for authorized users
    if (
      isAuthenticated.value &&
      Array<RouteRecordName>(
        "Home",
        "SignIn",
        "SignUp",
        "ForgotPassword",
        "SetPassword",
        "ConfirmInvitation",
        "Invite",
      ).includes(to.name!)
    ) {
      return next(getReturnUrlValue() || { name: "CategoriesAndSuppliers" });
    }

    return next();
  });

  return router;
}
