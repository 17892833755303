import { graphqlClient } from "@/core/api/graphql/client";
import sendContactUsRequestMutationDocument from "./sendContactUsRequest.graphql";
import type {
  InputContactUsRequestType,
  Mutations,
  ResultType,
  MutationsSendContactUsRequestArgs,
} from "@/core/api/graphql/types";

export async function sendContactUsRequest(payload: InputContactUsRequestType): Promise<ResultType> {
  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "sendContactUsRequest">>,
    MutationsSendContactUsRequestArgs
  >({
    mutation: sendContactUsRequestMutationDocument,
    variables: {
      command: payload,
    },
  });

  return data!.sendContactUsRequest;
}
