import { graphqlClient } from "@/core/api/graphql/client";
import { globals } from "@/core/globals";
import addTaxCertificatesMutation from "./opusAddSupplierTaxCertificates.graphql";
import type { MutationsAddTaxCertificatesArgs, Mutations, ResultType } from "@/core/api/graphql/types";
import type { InputTaxCertificateUploadRequestType } from "@/core/api/graphql/types";

export async function addTaxCertificates(taxCertificates: InputTaxCertificateUploadRequestType[]): Promise<ResultType> {
  const { userId } = globals;

  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "addTaxCertificates">>,
    MutationsAddTaxCertificatesArgs
  >({
    mutation: addTaxCertificatesMutation,
    variables: {
      command: {
        userId,
        taxCertificates,
      },
    },
  });
  return data!.addTaxCertificates;
}
