/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { cloneDeep, omit } from "lodash";
import { ref, computed } from "vue";
import { AddressType } from "@/core/enums";
import { isEqualAddresses } from "@/core/utilities";
import { useUser } from "@/shared/account";
import { useUserAddresses } from "@/shared/account/composables";
import { OpAddOrUpdateAddressModal } from "@/shared/cart/opus";
import { SelectAddressModal } from "@/shared/checkout";
import { useOrganizationAddresses } from "@/shared/company";
import { useModal } from "@/shared/modal";
import { useNotifications } from "@/shared/notification";
import { opUseSupplierSearch, useSuppliers } from "@/shared/opus";
import { OpSupplierSearchDialog } from "../components";
import { RequestType } from "../types";
import type { IQuickConnectPreparedItem, ShortContactType, ShortProductType } from "../types";
import type {
  InputCreateQuoteRequestsType,
  InputQuoteAddressType,
  InputQuoteItemType,
  OpusQuoteType,
  OpusMemberAddressType,
  QuoteReferralInfoType,
  SupplierType,
} from "@/core/api/graphql/types";
import type { AnyAddressType } from "@/core/types";

const selectedAddress = ref<InputQuoteAddressType | undefined>(undefined);
const selectedSuppliers = ref<SupplierType[]>([]);
const currentSupplier = ref<SupplierType | null>(null);

export function useQuickConnectData() {
  const { searchSuppliersWithFacets, resetFilters } = opUseSupplierSearch({ withFacets: true });
  const { openModal, closeModal } = useModal();
  const { user, isCorporateMember } = useUser();
  const { addresses: organizationsAddresses } = useOrganizationAddresses(user.value.contact?.organizationId || "");
  const { contactAddresses: personalAddresses } = useUserAddresses();

  const accountAddresses = computed<AnyAddressType[]>(() => {
    const { firstName, lastName } = user.value.contact ?? {};

    if (!isCorporateMember.value) {
      return organizationsAddresses.value.reduce((addresses: AnyAddressType[], address: OpusMemberAddressType) => {
        if (address.isActive) {
          addresses.push({ ...address, firstName, lastName });
        }
        return addresses;
      }, []);
    } else {
      const shippingAddresses = personalAddresses.value?.shippingAddresses || [];
      return shippingAddresses.filter((address: OpusMemberAddressType) => address.isActive);
    }
  });

  const userHasAddresses = computed<boolean>(() => !!accountAddresses.value.length);

  const convertOldPurposeName = (oldPurpose: string) => {
    if (oldPurpose === "Service Inquiry") {
      oldPurpose = "Services";
    }
    const firstLetter = oldPurpose?.[0]?.toLowerCase();
    return oldPurpose?.replace(/^./, firstLetter).replace(/\s/g, "") || "";
  };

  const createFromReferralInfo = (referralInfo?: QuoteReferralInfoType) => {
    if (referralInfo?.productCode && referralInfo?.productName) {
      return [
        {
          name: referralInfo.productName,
          sku: referralInfo.productCode,
          quantity: 1,
          removable: false,
        },
      ];
    } else {
      return [];
    }
  };

  const prepareQuickConnectRequest = (
    qcData: IQuickConnectPreparedItem,
    isSalesSupport: boolean,
    itemsToSubmit: ShortProductType[],
  ) => {
    const quickConnectDataRequest: InputCreateQuoteRequestsType = {
      comment: qcData.requestType === RequestType.Services ? "" : qcData.notes,
      shippingAddress: qcData.selectedAddress || undefined,
      supplierIds: qcData.selectedSuppliers?.map((supplier: SupplierType) => supplier?.id),
      email: qcData.contact?.email || "",
      firstName: qcData.contact?.firstName,
      lastName: qcData.contact?.lastName,
      phone: qcData.contact?.phone || "",
      purpose: qcData.requestType || "",
      items: isSalesSupport ? [] : (itemsToSubmit as InputQuoteItemType[]),
      attachments: qcData.uploadedFiles.map((item: FileType) => item.url) as string[],
    };

    return quickConnectDataRequest;
  };

  function openAddOrUpdateAddressModal(addressType: AddressType, currentAddress?: AnyAddressType): void {
    openModal({
      component: OpAddOrUpdateAddressModal,
      props: {
        new: !currentAddress,
        enableAddressValidation: true,
        address: {
          firstName: user.value.contact?.firstName,
          lastName: user.value.contact?.lastName,
          email: user.value.email,
          phone: user.value.phoneNumber,
          organization: user.value.contact?.organizations?.items?.[0].name,
          countryCode: "USA",
          countryName: "United States of America",
        },
        onResult(updatedAddress: OpusMemberAddressType): void {
          const address = cloneDeep({ ...updatedAddress, addressType }) as InputQuoteAddressType;
          selectedAddress.value = omit(address, "isDefault", "isActive", "description", "isFavorite");
          closeModal();

          // Save address
          const addressToSave = { ...updatedAddress, addressType: AddressType.Shipping };

          if (accountAddressExists(addressToSave)) {
            return;
          }
        },
      },
    });
  }

  function openSelectAddressModal(addressType: AddressType): void {
    openModal({
      component: SelectAddressModal,
      props: {
        addresses: accountAddresses.value,
        currentAddress: addressType,
        isCorporateAddresses: isCorporateMember.value,
        onResult(address: OpusMemberAddressType): void {
          const _address = omit(address, "isDefault", "isActive", "description", "isFavorite");
          selectedAddress.value = cloneDeep({ ..._address, addressType }) as InputQuoteAddressType;
          closeModal();
        },

        onAddNewAddress() {
          setTimeout(() => {
            openAddOrUpdateAddressModal(addressType);
          }, 500);
        },
      },
    });
  }

  function openSelectSuppliersModal() {
    openModal({
      component: OpSupplierSearchDialog,
      props: {
        selectedSuppliers: selectedSuppliers.value,
        notEditableItems: currentSupplier.value ? [currentSupplier.value] : [],
        onResult(updatedSuppliers: SupplierType[]): void {
          closeModal();
          resetFilters();
          selectedSuppliers.value = updatedSuppliers;
        },
      },
    });
  }

  function accountAddressExists(address: AnyAddressType): boolean {
    return accountAddresses.value.some((item: AnyAddressType) => isEqualAddresses(item, address));
  }

  return {
    prepareQuickConnectRequest,
    convertOldPurposeName,
    createFromReferralInfo,
    openSelectAddressModal,
    openAddOrUpdateAddressModal,
    openSelectSuppliersModal,
    accountAddresses,
    userHasAddresses,
    selectedAddress,
    selectedSuppliers,
    currentSupplier,
  };
}
