<template>
  <VcChip v-if="isDigital" :size="size" variant="solid-light" color="info" rounded>
    {{ $t("common.labels.digital_product") }}
  </VcChip>

  <VcChip v-else-if="isInStock" :size="size" variant="solid-light" color="success" rounded>
    <!-- OPUS HOTFIX -->
    <span
      v-if="quantity"
      class="inline-block text-center"
      :class="{ 'max-w-5': quantity < 1000, 'min-w-5': quantity > 1000 }"
    >
      <!-- !OPUS HOTFIX -->
      {{ inStockQuantityLabel }}
    </span>

    <span
      v-if="textEnabled"
      :class="{
        'ms-1': quantity,
      }"
    >
      {{ quantity ? $t("common.suffixes.product_count_in_stock") : $t("common.labels.in_stock") }}
    </span>
  </VcChip>

  <VcChip v-else-if="!isAvailable" :size="size" variant="solid-light" color="danger" rounded>
    {{ $t("common.messages.product_no_longer_available") }}
  </VcChip>

  <VcChip v-else :size="size" variant="solid-light" color="danger" rounded>
    {{ $t("common.messages.product_out_of_stock") }}
  </VcChip>

  <!-- OPUS -->
  <VcChip
    v-if="shipsDays && props.quantity && props.quantity >= MAX_DISPLAY_IN_STOCK_QUANTITY"
    :size="size"
    variant="solid-light"
    color="info"
    rounded
  >
    {{ $t("common.messages.shipping_in", { value: shipsDays }) }}
  </VcChip>
  <!-- !OPUS -->
</template>

<script setup lang="ts">
import { computed } from "vue";
import { MAX_DISPLAY_IN_STOCK_QUANTITY } from "@/core/constants";

interface IProps {
  isInStock?: boolean;
  isAvailable?: boolean;
  isDigital?: boolean;
  quantity?: number | null;
  size?: "xs" | "sm" | "md" | "lg";
  textEnabled?: boolean;
  // OPUS
  shipsDays?: number;
  // !OPUS
}

const props = withDefaults(defineProps<IProps>(), {
  isAvailable: true,
  quantity: null,
  size: "xs",
  textEnabled: true,
});

const inStockQuantityLabel = computed<string>(() =>
  props.quantity && props.quantity > MAX_DISPLAY_IN_STOCK_QUANTITY
    ? `${MAX_DISPLAY_IN_STOCK_QUANTITY}+`
    : props.quantity!.toString(),
);
</script>
