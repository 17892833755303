import { ref } from "vue";
import {
  confirmSupplierOrder as _confirmSupplierOrder,
  cancelSupplierOrder as _cancelSupplierOrder,
} from "@/core/api/graphql";
import { useAuth } from "@/core/composables/useAuth";
import { Logger } from "@/core/utilities";
import type { InputCancelSupplierOrderType, InputConfirmSupplierOrderType, ResultType } from "@/core/api/graphql/types";

const loading = ref(false);
const { setTokenType, setAccessToken, unauthorize } = useAuth();

export function useSupplierOrder() {
  async function confirmSupplierOrder(payload: InputConfirmSupplierOrderType, token: string): Promise<ResultType> {
    loading.value = true;

    try {
      setTokenType("Bearer");
      setAccessToken(token);
      return await _confirmSupplierOrder(payload);
    } catch (e) {
      Logger.error(`${useSupplierOrder.name}.${cancelSupplierOrder.name}`, e);
      throw e;
    } finally {
      void unauthorize();
      loading.value = false;
    }
  }

  async function cancelSupplierOrder(payload: InputCancelSupplierOrderType, token: string): Promise<ResultType> {
    loading.value = true;

    try {
      setTokenType("Bearer");
      setAccessToken(token);
      return await _cancelSupplierOrder(payload);
    } catch (e) {
      Logger.error(`${useSupplierOrder.name}.${cancelSupplierOrder.name}`, e);
      throw e;
    } finally {
      void unauthorize();
      loading.value = false;
    }
  }

  return {
    loading,
    confirmSupplierOrder,
    cancelSupplierOrder,
  };
}
