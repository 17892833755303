import { canUseDOM } from "@apollo/client/utilities";
import { useScriptTag } from "@vueuse/core";
import { useModuleSettings } from "@/core/composables/useModuleSettings";
import { Logger } from "@/core/utilities";

const DEBUG_PREFIX = "[HubSpot]";
const MODULE_ID = "Opus";
const SETTINGS_MAPPING = {
  "Opus.Analytics.HubSpotTagAccountId": "hubSpotAccountId",
  "Opus.Analytics.HubSpotTagEnabled": "hubSpotTagEnabled",
};

const { getModuleSettings } = useModuleSettings(MODULE_ID);

type ModuleSettingsType = { hubSpotAccountId: string; hubSpotTagEnabled: boolean };

export function useHubSpot() {
  function init() {
    if (!canUseDOM) {
      return;
    }

    const { hubSpotAccountId, hubSpotTagEnabled } = getModuleSettings(SETTINGS_MAPPING) as ModuleSettingsType;

    if (hubSpotTagEnabled && hubSpotAccountId) {
      const scriptSrc = `//js.hs-scripts.com/${hubSpotAccountId}.js`;

      const { load } = useScriptTag(scriptSrc, undefined, {
        async: true,
        defer: true,
        attrs: { id: "hs-script-loader" },
      });

      load().catch((err) => {
        Logger.error(DEBUG_PREFIX, "Failed to load HubSpot script:", err);
      });
    }
  }

  return {
    init,
  };
}
