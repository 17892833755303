import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import getProductsOffersQueryDocument from "./getProductOffersQuery.graphql";
import type { Product, Query, QueryProductArgs } from "@/core/api/graphql/types";

export async function getProductOffers(id: string): Promise<Product | null> {
  const { storeId, userId, cultureName, currencyCode } = globals;

  const { data } = await graphqlClient.query<Required<Pick<Query, "product">>, QueryProductArgs>({
    query: getProductsOffersQueryDocument,
    variables: {
      storeId,
      userId,
      cultureName,
      currencyCode,
      id,
    },
  });

  return data.product;
}
