import { graphqlClient } from "../../../client";
import mutationDocument from "./updateMemberDynamicPropertiesMutation.graphql";
import type {
  InputUpdateMemberDynamicPropertiesType,
  MemberType,
  Mutations,
  MutationsUpdateMemberDynamicPropertiesArgs,
} from "@/core/api/graphql/types";

export async function updateMemberDynamicPropertiesData(
  dynProps: InputUpdateMemberDynamicPropertiesType,
): Promise<MemberType> {
  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "updateMemberDynamicProperties">>,
    MutationsUpdateMemberDynamicPropertiesArgs
  >({
    mutation: mutationDocument,
    variables: {
      command: dynProps,
    },
  });

  return data!.updateMemberDynamicProperties;
}
