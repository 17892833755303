import { createGlobalState, createSharedComposable, useDebounceFn } from "@vueuse/core";
import { omit } from "lodash";
import { computed, readonly, ref, shallowRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { createOrderFromCart as _createOrderFromCart, runProcessMainOrderWorkflow } from "@/core/api/graphql";
import { useGoogleAnalytics, useHistoricalEvents, useThemeContext } from "@/core/composables";
import { AddressType, ProductType } from "@/core/enums";
import { globals } from "@/core/globals";
import { isEqualAddresses, isPoNumberRequired, Logger } from "@/core/utilities";
import { useUser, useUserAddresses, useUserCheckoutDefaults } from "@/shared/account";
import { useFullCart, EXTENDED_DEBOUNCE_IN_MS } from "@/shared/cart";
import { OpAddOrUpdateAddressModal } from "@/shared/cart/opus";
import { useOrganizationAddresses } from "@/shared/company";
import { useModal } from "@/shared/modal";
import { useNotifications } from "@/shared/notification";
import { PaymentMethodGroupType } from "@/shared/payment";
import type { OpusPaymentExtraFieldsType } from "../opus/types";
import type {
  OpusCartAddressType,
  InputAddressType,
  InputPaymentType,
  OpusCustomerOrderType,
  OpusPaymentMethodType,
  OpusPaymentType,
  OpusShippingMethodType,
  OpusShipmentType,
  OpusMemberAddressType,
} from "@/core/api/graphql/types";
import type { AnyAddressType } from "@/core/types";
import type { OpusVendorCartType } from "@/shared/cart/opus";
import SelectAddressModal from "@/shared/checkout/components/select-address-modal.vue";

const useGlobalCheckout = createGlobalState(() => {
  const loading = ref(false);
  const billingAddressEqualsShipping = ref(true);
  const placedOrder = shallowRef<OpusCustomerOrderType | null>(null);

  const commentChanging = ref(false);
  const _comment = ref<string>();

  const purchaseOrderNumberChanging = ref(false);
  const _purchaseOrderNumber = ref<string>();

  function clearState() {
    _comment.value = undefined;
  }
  return {
    loading,
    billingAddressEqualsShipping,
    placedOrder,
    commentChanging,
    _comment,
    purchaseOrderNumberChanging,
    _purchaseOrderNumber,
    clearState,
  };
});

export function _useCheckout() {
  const ga = useGoogleAnalytics();
  const { t } = useI18n();
  const notifications = useNotifications();
  const { openModal, closeModal } = useModal();
  const router = useRouter();
  const { user, isAuthenticated, isCorporateMember } = useUser();
  const { getUserCheckoutDefaults } = useUserCheckoutDefaults();
  const {
    contactAddresses: personalAddresses,
    fetchAddresses: fetchPersonalAddresses,
    addOrUpdateAddresses: addOrUpdatePersonalAddresses,
  } = useUserAddresses();
  const {
    addresses: organizationsAddresses,
    fetchAddresses: fetchOrganizationAddresses,
    addOrUpdateAddresses: addOrUpdateOrganizationAddresses,
  } = useOrganizationAddresses(user.value.contact?.organizationId || "");
  const {
    refetch: refetchCart,
    cart,
    selectedLineItems,
    selectCartItems,
    shipment,
    payment,
    availableShippingMethods,
    availablePaymentMethods,
    hasValidationErrors,
    allItemsAreDigital,
    updateShipment,
    removeShipment,
    updatePayment,
    changeComment,
    updatePurchaseOrderNumber,
    // OPUS
    shipments,
    payments,
    opusVendorCarts,
    selectedOpusVendorCarts,
    approvalFlowEnable,
    partialApprovalFlowEnable,
    bulkUpdateShipment,
    bulkUpdatePayment,
    // !OPUS
  } = useFullCart();
  const {
    loading,
    billingAddressEqualsShipping,
    placedOrder,
    commentChanging,
    _comment,
    purchaseOrderNumberChanging,
    _purchaseOrderNumber,
    clearState: clearGlobalCheckoutState,
  } = useGlobalCheckout();
  const { themeContext } = useThemeContext();
  const { pushHistoricalEvent } = useHistoricalEvents();

  const deliveryAddress = computed(() => shipment.value?.deliveryAddress);
  const billingAddress = computed(() =>
    !allItemsAreDigital.value && billingAddressEqualsShipping.value && deliveryAddress.value
      ? deliveryAddress.value
      : payment.value?.billingAddress,
  );
  const shipmentMethod = computed(() =>
    availableShippingMethods.value.find(
      (item) => item.id === shipment.value?.shipmentMethodCode + "_" + shipment.value?.shipmentMethodOption,
    ),
  );
  const paymentMethod = computed(() =>
    availablePaymentMethods.value.find((item) => item.code === payment.value?.paymentGatewayCode),
  );

  const changeCommentDebounced = useDebounceFn(async (value: string) => {
    if (cart.value?.comment !== value) {
      await changeComment(value);
    }
    commentChanging.value = false;
  }, EXTENDED_DEBOUNCE_IN_MS);

  const updatePurchaseOrderNumberDebounced = useDebounceFn(async (value: string) => {
    if (cart.value?.purchaseOrderNumber !== value) {
      await updatePurchaseOrderNumber(value);
    }
    purchaseOrderNumberChanging.value = false;
  }, EXTENDED_DEBOUNCE_IN_MS);

  const comment = computed({
    get: () => _comment.value ?? cart.value?.comment ?? "",
    set: (value: string) => {
      commentChanging.value = true;
      _comment.value = value;
      void changeCommentDebounced(value.trim());
    },
  });

  const purchaseOrderNumber = computed({
    get: () => _purchaseOrderNumber.value ?? cart.value?.purchaseOrderNumber ?? "",
    set: (value: string) => {
      purchaseOrderNumberChanging.value = true;
      _purchaseOrderNumber.value = value;
      void updatePurchaseOrderNumberDebounced(value);
    },
  });

  const filteredShipments = computed<OpusShipmentType[]>(() => {
    return shipments.value?.filter((shipmentItem) =>
      selectedOpusVendorCarts.value.some((vendorCartItem) => shipmentItem.vendor?.id === vendorCartItem.vendor.id),
    );
  });

  const isValidDeliveryAddress = computed<boolean>(
    () =>
      filteredShipments.value.length === selectedOpusVendorCarts.value.length &&
      filteredShipments.value?.every((item) => !!item.deliveryAddress),
  );

  const isValidBillingAddress = computed<boolean>(() =>
    selectedOpusVendorCarts.value?.every(
      (item) => !!item.payment?.billingAddress || item.payment?.paymentGatewayCode === "PurchaseOrderPaymentMethod",
    ),
  );
  const isValidShipmentMethod = computed<boolean>(() =>
    filteredShipments.value?.every((item) => !!item.shipmentMethodCode),
  );

  const isValidPaymentMethod = computed<boolean>(() => payments.value?.every((item) => !!item.paymentGatewayCode));

  const isValidShipment = computed<boolean>(() => isValidDeliveryAddress.value && isValidShipmentMethod.value);
  const isValidPayment = computed<boolean>(
    () => isValidBillingAddress.value && isValidPaymentMethod.value && isAllRequiredPoNumbersFilled.value,
  );
  const isValidCheckout = computed<boolean>(() =>
    allItemsAreDigital.value
      ? isValidPayment.value && !hasValidationErrors.value
      : isValidShipment.value && isValidPayment.value && !hasValidationErrors.value,
  );

  const selectedPaymentMethodGroupType = computed<string | undefined>(() => {
    const paymentMethodCode = placedOrder.value?.inPayments[0].paymentMethod?.code ?? payment.value?.paymentGatewayCode;
    return availablePaymentMethods.value.find((item) => item.code === paymentMethodCode)?.paymentMethodGroupType;
  });

  const canPayNow = computed<boolean>(
    () =>
      isAuthenticated.value &&
      // !!selectedPaymentsMethodGroupType.value &&
      // selectedPaymentMethodGroupType.value !== PaymentMethodGroupType[PaymentMethodGroupType.Manual]
      !!selectedPaymentsMethodGroupType.value.length &&
      selectedPaymentsMethodGroupType.value.some(
        (item) => item !== PaymentMethodGroupType[PaymentMethodGroupType.Manual],
      ),
  );

  const billingAddresses = computed<OpusMemberAddressType[]>(() => {
    const { firstName, lastName } = user.value.contact ?? {};

    // OPUS
    // return isCorporateMember.value
    //   ? organizationsAddresses.value.map((address) => ({ ...address, firstName, lastName }))
    //   : personalAddresses.value;
    return personalAddresses.value?.billingAddresses.map((address) => ({ ...address, firstName, lastName })) || [];
    // !OPUS
  });

  const shippingAddresses = computed<OpusMemberAddressType[]>(() => {
    const { firstName, lastName } = user.value.contact ?? {};

    // OPUS
    // return isCorporateMember.value
    //   ? organizationsAddresses.value.map((address) => ({ ...address, firstName, lastName }))
    //   : personalAddresses.value;
    return personalAddresses.value?.shippingAddresses.map((address) => ({ ...address, firstName, lastName })) || [];
    // !OPUS
  });

  const isPurchaseOrderNumberEnabled = computed<boolean>(
    () =>
      !!selectedPaymentMethodGroupType.value &&
      selectedPaymentMethodGroupType.value === PaymentMethodGroupType[PaymentMethodGroupType.Manual],
  );

  const allOrderItemsAreDigital = computed(() =>
    placedOrder.value?.items?.every((item) => item.productType === ProductType.Digital),
  );

  // OPUS
  const selectedPaymentsMethodGroupType = computed<(string | undefined)[]>(() => {
    const paymentMethodCodes =
      placedOrder.value?.inPayments.map((item) => item.paymentMethod?.code) ||
      selectedOpusVendorCarts.value
        .filter((item) => !item.vendor?.supplier?.isOrderSetupRequestRequired)
        .map((item) => item.payment?.paymentGatewayCode) ||
      [];
    return availablePaymentMethods.value
      .filter((item) => paymentMethodCodes.includes(item.code))
      .map((item) => item.paymentMethodGroupType);
  });

  const paymentErrorMessage = ref<string | null | undefined>();
  // !OPUS

  function isExistAddress(address: AnyAddressType): boolean {
    return (
      billingAddresses.value.some((item) => isEqualAddresses(item, address)) ||
      shippingAddresses.value.some((item) => isEqualAddresses(item, address))
    );
  }

  async function setShippingMethod(method: OpusShippingMethodType, vendorCart?: OpusVendorCartType): Promise<void> {
    await updateShipment({
      id: vendorCart?.shipment?.id,
      price: method.price?.amount,
      shipmentMethodCode: method.code,
      shipmentMethodOption: method.optionName,
      vendorId: vendorCart?.vendor.id,
    });
  }

  async function setShippingComment(shippingComment: string, vendorCart?: OpusVendorCartType): Promise<void> {
    await updateShipment({
      id: vendorCart?.shipment?.id,
      comment: shippingComment,
      vendorId: vendorCart?.vendor.id,
    });
    // OPUS
    // ga.addShippingInfo(cart.value!);
    // !OPUS
  }

  async function setPaymentMethod(method: OpusPaymentMethodType, vendorCart?: OpusVendorCartType): Promise<void> {
    await updatePayment({
      id: vendorCart?.payment?.id,
      paymentGatewayCode: method.code,
      vendorId: vendorCart?.vendor.id,
    });
  }

  // OPUS
  const isAllRequiredPoNumbersFilled = computed<boolean>(() => {
    return selectedOpusVendorCarts.value
      ?.filter(isPoNumberRequired)
      .every((opusCart) => !!opusCart.payment?.purchaseOrderNumber);
  });

  async function updatePaymentDetails(paymentExtraFields: OpusPaymentExtraFieldsType, vendorCart?: OpusVendorCartType) {
    await updatePayment(
      {
        id: vendorCart?.payment?.id,
        vendorId: vendorCart?.vendor.id,
        comment: paymentExtraFields.comment,
      },
      {
        purchaseOrderNumber: paymentExtraFields.purchaseOrderNumber,
        generalLedgerNumber: paymentExtraFields.generalLedgerNumber,
        requisitionNumber: paymentExtraFields.requisitionNumber,
        requestSetupAccountBillingMethod: paymentExtraFields.requestSetupAccountBillingMethod,
      },
    );
    // OPUS
    // ga.addPaymentInfo(cart.value!);
    // !OPUS
  }

  async function setShippingContactPhoneNumber(
    contactPhoneNumber: string,
    vendorCart?: OpusVendorCartType,
  ): Promise<void> {
    await updateShipment(
      {
        id: vendorCart?.shipment?.id,
        vendorId: vendorCart?.vendor.id,
      },
      contactPhoneNumber,
    );
    // OPUS
    // ga.addShippingInfo(cart.value!);
    // !OPUS
  }
  // !OPUS

  watch(allItemsAreDigital, async (value, previousValue) => {
    // Update defaults if state changed not on initialization
    if (previousValue !== undefined && value !== undefined && previousValue !== value) {
      await setCheckoutDefaults();
    }
  });

  async function setCheckoutDefaults(): Promise<void> {
    const { shippingMethodId, paymentMethodCode } = getUserCheckoutDefaults();
    const defaultShippingMethod = availableShippingMethods.value.find((item) => item.id === shippingMethodId);
    const defaultPaymentMethod = availablePaymentMethods.value.find((item) => item.code === paymentMethodCode);

    if (allItemsAreDigital.value && shipment.value) {
      await removeShipment(shipment.value.id);
    }

    // Create at initialization to prevent duplication due to lack of id
    if (!allItemsAreDigital.value && !shipment.value?.shipmentMethodCode && !shipment.value?.shipmentMethodOption) {
      if (shippingMethodId && defaultShippingMethod) {
        await setShippingMethod(defaultShippingMethod);
      } else if (!shipment.value) {
        // OPUS
        // await updateShipment({});
        // !OPUS
      }
    }

    if (!payment.value?.paymentGatewayCode) {
      if (paymentMethodCode && defaultPaymentMethod) {
        await setPaymentMethod(defaultPaymentMethod);
      } else if (!payment.value) {
        // OPUS
        // await updatePayment({});
        // !OPUS
      }
    }
  }

  async function initialize(): Promise<void> {
    placedOrder.value = null;
    loading.value = true;

    await setCheckoutDefaults();

    void fetchAddresses();

    ga.beginCheckout({ ...cart.value!, items: selectedLineItems.value });

    loading.value = false;
  }

  async function updateBillingOrDeliveryAddress(
    addressType: AddressType,
    inputAddress: InputAddressType,
    vendorCart?: OpusVendorCartType,
  ): Promise<void> {
    if (
      addressType === AddressType.Billing &&
      (!payment.value?.billingAddress || !isEqualAddresses(payment.value?.billingAddress, inputAddress))
    ) {
      await updatePayment({
        id: vendorCart?.payment?.id,
        billingAddress: omit(inputAddress, "isActive"),
        vendorId: vendorCart?.vendor.id,
      });
    } else {
      await updateShipment({
        id: vendorCart?.shipment?.id,
        deliveryAddress: omit(inputAddress, "isActive"),
        vendorId: vendorCart?.vendor.id,
      });
    }
  }

  function openAddOrUpdateAddressModal(
    addressType: AddressType,
    editableAddress?: OpusMemberAddressType | OpusCartAddressType,
    vendorCart?: OpusVendorCartType,
  ): void {
    openModal({
      component: OpAddOrUpdateAddressModal,
      props: {
        new: true,
        address: {
          firstName: user.value.contact?.firstName,
          lastName: user.value.contact?.lastName,
          email: user.value.email,
          phone: user.value.phoneNumber,
          organization: user.value.contact?.organizations?.items?.[0].name,
          countryCode: "USA",
          countryName: "United States of America",
          description: "",
        },
        enableAddressValidation: addressType === AddressType.Shipping,
        async onResult(address: OpusMemberAddressType) {
          closeModal();

          const inputAddress: InputAddressType = {
            ...omit(address, ["id", "isDefault", "isActive", "isFavorite"]),
            addressType,
          };

          await updateBillingOrDeliveryAddress(addressType, inputAddress, vendorCart);
          await fetchAddresses();
        },
      },
    });
  }

  function openSelectAddressModal(
    addressType: AddressType,
    addresses: OpusMemberAddressType[],
    vendorCart?: OpusVendorCartType,
  ): void {
    // OPUS
    const vendorId = vendorCart?.vendor.id;
    // !OPUS
    openModal({
      component: SelectAddressModal,

      props: {
        addresses: addresses,
        currentAddress:
          addressType === AddressType.Billing
            ? payments.value?.find((item: OpusPaymentType) => item.vendor?.id === vendorId)?.billingAddress
            : shipments.value?.find((item: OpusShipmentType) => item.vendor?.id === vendorId)?.deliveryAddress,
        isCorporateAddresses: isCorporateMember.value,

        async onResult(address?: OpusMemberAddressType) {
          if (!address) {
            return;
          }

          const inputAddress: InputAddressType = {
            ...omit(address, ["id", "isDefault", "isActive", "isFavorite"]),
            addressType,
          };

          await updateBillingOrDeliveryAddress(addressType, inputAddress, vendorCart);
        },

        onAddNewAddress() {
          setTimeout(() => {
            openAddOrUpdateAddressModal(addressType, undefined, vendorCart);
          }, 500);
        },
      },
    });
  }

  async function fetchAddresses(): Promise<void> {
    if (!isAuthenticated.value) {
      return;
    }

    // OPUS
    // if (isCorporateMember.value) {
    //   await fetchOrganizationAddresses();
    // } else {
    //   await fetchPersonalAddresses();
    // }
    await fetchPersonalAddresses();
    // !OPUS
  }

  function onDeliveryAddressChange(vendorCart?: OpusVendorCartType): void {
    shippingAddresses.value.length
      ? openSelectAddressModal(AddressType.Shipping, shippingAddresses.value, vendorCart)
      : openAddOrUpdateAddressModal(AddressType.Shipping, shipment.value?.deliveryAddress, vendorCart);
  }

  function onBillingAddressChange(vendorCart?: OpusVendorCartType): void {
    billingAddresses.value.length
      ? openSelectAddressModal(AddressType.Billing, billingAddresses.value, vendorCart)
      : openAddOrUpdateAddressModal(AddressType.Billing, payment.value?.billingAddress, vendorCart);
  }

  function getNewAddresses(payload: {
    shippingAddress?: OpusCartAddressType;
    billingAddress?: OpusCartAddressType;
  }): OpusMemberAddressType[] {
    const newAddresses: OpusMemberAddressType[] = [];

    if (payload.shippingAddress && !isExistAddress(payload.shippingAddress)) {
      newAddresses.push({
        ...payload.shippingAddress,
        isDefault: false,
        isFavorite: false,
        addressType: AddressType.BillingAndShipping,
        isActive: false,
      });
    }
    if (
      payload.billingAddress &&
      !isExistAddress(payload.billingAddress) &&
      (!payload.shippingAddress || !isEqualAddresses(payload.shippingAddress, payload.billingAddress))
    ) {
      newAddresses.push({
        ...payload.billingAddress,
        isDefault: false,
        isFavorite: false,
        addressType: AddressType.BillingAndShipping,
        isActive: false,
      });
    }

    return newAddresses;
  }

  async function saveNewAddresses(payload: {
    shippingAddress?: OpusCartAddressType;
    billingAddress?: OpusCartAddressType;
  }): Promise<void> {
    const newAddresses: OpusMemberAddressType[] = getNewAddresses(payload);

    if (isCorporateMember.value) {
      await addOrUpdateOrganizationAddresses(newAddresses);
    } else {
      await addOrUpdatePersonalAddresses(newAddresses);
    }
  }

  async function prepareOrderData(): Promise<void> {
    // Update payment with required properties
    // const filledPayment: InputPaymentType = {
    //   id: payment.value!.id,
    //   amount: cart.value.total!.amount, // required
    // };

    // Save shipping address as billing address
    // if (billingAddressEqualsShipping.value) {
    //   filledPayment.billingAddress = {
    //     ...shipment.value!.deliveryAddress,
    //     addressType: AddressType.Billing,
    //   };
    // }

    // OPUS
    const newPayments: InputPaymentType[] = [];

    for (const item of selectedOpusVendorCarts.value) {
      if (item.payment?.paymentGatewayCode === "PurchaseOrderPaymentMethod") {
        const filledPayment: InputPaymentType = {
          id: item.payment?.id,
          billingAddress: {
            ...omit(item.shipment?.deliveryAddress, ["parentId"]),
            addressType: AddressType.Billing,
            key: item.shipment?.deliveryAddress?.parentId,
          },
          vendorId: item.vendor.id,
          amount: cart.value!.total!.amount, // required
        };
        newPayments.push(filledPayment);
      }
    }

    if (newPayments.length) {
      await bulkUpdatePayment(newPayments);
    }
    // OPUS

    // await updatePayment(filledPayment);

    // Reset purchase order number if it is unavailable
    if (!isPurchaseOrderNumberEnabled.value && purchaseOrderNumber.value) {
      await updatePurchaseOrderNumber("");
    }

    // Parallel saving of new addresses in account. Before cleaning shopping cart
    // if (isAuthenticated.value) {
    //   saveNewAddresses({
    //     shippingAddress: !allItemsAreDigital.value ? shipment.value?.deliveryAddress : undefined,
    //     billingAddress: payment.value!.billingAddress,
    //   });
    // }
    // !OPUS
  }

  async function createOrderFromCart(): Promise<OpusCustomerOrderType | null> {
    // TODO: why canPayNow.value changed before fetchFullCart()?
    const perviousСanPayNow = canPayNow.value;
    loading.value = true;

    await prepareOrderData();

    try {
      placedOrder.value = (await _createOrderFromCart(cart.value!.id)) as OpusCustomerOrderType;
    } catch (e) {
      Logger.error(`${useCheckout.name}.${createOrderFromCart.name}`, e);
    }

    if (placedOrder.value) {
      await refetchCart();

      if (themeContext.value?.storeSettings?.defaultSelectedForCheckout && cart.value?.items.length) {
        selectCartItems(
          cart.value.items.map(
            (item) =>
              //OPUS
              item.vendor?.supplier?.isOrderAllowed ? item.id : "",
            //!OPUS
          ),
        );
      }
      //OPUS
      ga.purchase(placedOrder.value, placedOrder.value.id, "placed");
      // !OPUS
      clearState();

      approvalFlowEnable.value || partialApprovalFlowEnable.value
        ? await router.replace({
            name: "CheckoutSentForApproval",
            params: { status: perviousСanPayNow ? "card" : "manual" },
          })
        : (ga.purchase(placedOrder.value, placedOrder.value.id, "auto_approved"),
          void pushHistoricalEvent({
            eventType: "placeOrder",
            sessionId: placedOrder.value.id,
            productIds: placedOrder.value.items?.map((item) => item.productId),
            storeId: globals.storeId,
          }),
          await router.replace({ name: perviousСanPayNow ? "CheckoutPayment" : "CheckoutCompleted" }));
    } else {
      notifications.error({
        text: t("common.messages.creating_order_error"),
        duration: 15000,
        single: true,
      });
    }

    loading.value = false;

    return placedOrder.value;
  }

  function clearState() {
    clearGlobalCheckoutState();
  }

  // OPUS
  function onBulkDeliveryAddressChange() {
    shippingAddresses.value.length
      ? openBulkSelectAddressModal(AddressType.Shipping, shippingAddresses.value)
      : openBulkAddOrUpdateAddressModal(AddressType.Shipping, shipment.value?.deliveryAddress);
  }

  function openBulkSelectAddressModal(addressType: AddressType, addresses: OpusMemberAddressType[]): void {
    openModal({
      component: SelectAddressModal,

      props: {
        addresses: addresses,
        isCorporateAddresses: isCorporateMember.value,

        async onResult(address?: OpusMemberAddressType) {
          if (!address) {
            return;
          }

          const inputAddress: InputAddressType = {
            ...omit(address, ["isDefault", "isActive", "isFavorite"]),
            addressType,
          };

          await bulkUpdateBillingOrDeliveryAddress(addressType, inputAddress);
        },

        onAddNewAddress() {
          setTimeout(() => {
            openBulkAddOrUpdateAddressModal(addressType);
          }, 500);
        },
      },
    });
  }

  function openBulkAddOrUpdateAddressModal(
    addressType: AddressType,
    editableAddress?: OpusMemberAddressType | OpusCartAddressType,
  ) {
    openModal({
      component: OpAddOrUpdateAddressModal,
      props: {
        address: {
          ...editableAddress,
          firstName: user.value.contact?.firstName,
          lastName: user.value.contact?.lastName,
          email: user.value.email,
          phone: user.value.phoneNumber,
          organization: user.value.contact?.organizations?.items?.[0].name,
          countryCode: "USA",
          countryName: "United States of America",
        },
        enableAddressValidation: addressType === AddressType.Shipping,
        new: true,

        async onResult(address: OpusMemberAddressType) {
          closeModal();

          const inputAddress: InputAddressType = {
            ...omit(address, ["id", "isDefault", "isActive", "isFavorite"]),
            addressType,
          };

          await bulkUpdateBillingOrDeliveryAddress(addressType, omit(inputAddress, "isActive"));
        },
      },
    });
  }

  async function bulkUpdateBillingOrDeliveryAddress(addressType: AddressType, inputAddress: InputAddressType) {
    if (addressType === AddressType.Billing) {
      await updatePayment({
        id: payment.value?.id,
        billingAddress: omit(inputAddress, "isActive"),
      });
    } else {
      const newShipments = selectedOpusVendorCarts.value.map((item: OpusVendorCartType) => {
        return {
          id: item.shipment?.id,
          deliveryAddress: inputAddress,
          vendorId: item.vendor.id,
        };
      });

      await bulkUpdateShipment(newShipments);
      await fetchAddresses();
    }
  }

  async function onPaymentResult(success: boolean, errorMessage?: string | null | undefined) {
    loading.value = true;

    try {
      if (success && placedOrder.value?.id) {
        await runProcessMainOrderWorkflow({ mainOrderId: placedOrder.value?.id });
      } else {
        paymentErrorMessage.value = errorMessage;
      }
    } catch (e) {
      Logger.error(`${useCheckout.name}.${onPaymentResult.name}`, e);
    } finally {
      loading.value = false;
      await router.replace({
        name: "CheckoutPaymentResult",
        params: { status: success ? "success" : "failure" },
      });
    }
  }
  // !OPUS

  return {
    deliveryAddress,
    shipmentMethod,
    billingAddress,
    paymentMethod,
    comment,
    billingAddressEqualsShipping,
    purchaseOrderNumber,
    isValidDeliveryAddress,
    isValidBillingAddress,
    isValidShipmentMethod,
    isValidPaymentMethod,
    isValidShipment,
    isValidPayment,
    isValidCheckout,
    isPurchaseOrderNumberEnabled,
    selectedPaymentMethodGroupType,
    canPayNow,
    initialize,
    onDeliveryAddressChange,
    onBillingAddressChange,
    setShippingMethod,
    setPaymentMethod,
    createOrderFromCart,
    loading: readonly(loading),
    changing: computed(() => commentChanging.value || purchaseOrderNumberChanging.value),
    placedOrder: computed(() => placedOrder.value),
    allOrderItemsAreDigital,
    // OPUS
    opusVendorCarts,
    onBulkDeliveryAddressChange,
    updatePaymentDetails,
    setShippingComment,
    setShippingContactPhoneNumber,
    onPaymentResult,
    paymentErrorMessage,
    // !OPUS
  };
}

export const useCheckout = createSharedComposable(_useCheckout);
