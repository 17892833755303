import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import getSuppliersQueryDocument from "./getSuppliersQuery.graphql";
import type { Query, QuerySuppliersArgs, SupplierConnection, SupplierType } from "@/core/api/graphql/types";

export async function getSuppliers(payload: Omit<QuerySuppliersArgs, "storeId" | "userId">): Promise<SupplierConnection> {
  const { storeId, userId } = globals;

  const { data } = await graphqlClient.query<Required<Pick<Query, "suppliers">>, QuerySuppliersArgs>({
    query: getSuppliersQueryDocument,
    variables: {
      storeId,
      userId,
      ...payload,
    },
  });

  return data.suppliers;
}
