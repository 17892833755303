import { graphqlClient } from "../../../client";
import getSupplierQueryDocument from "./getSupplierQuery.graphql";
import type { Query, QuerySupplierArgs, SupplierType } from "@/core/api/graphql/types";

export async function getSupplier(id: string): Promise<SupplierType> {
  const { data } = await graphqlClient.query<Required<Pick<Query, "supplier">>, QuerySupplierArgs>({
    query: getSupplierQueryDocument,
    variables: {
      id: id,
    },
  });

  return data.supplier!;
}
