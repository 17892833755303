<template>
  <!-- Mobile header -->
  <MobileHeader v-if="isMobile" />

  <!-- Desktop header -->
  <template v-else>
    <TopHeader :class="{ 'border-b border-neutral-300': !isSignUpPage }" class="relative z-[21] print:hidden" />
    <BottomHeader v-if="!isSignUpPage" class="sticky top-0 z-20 shadow-md print:hidden" />
  </template>

  <div class="hidden items-start justify-between print:flex">
    <VcImage :src="logoUrl" :alt="$context.storeName" class="h-12" />

    <Created />
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useWhiteLabeling } from "@/core/composables";
import Created from "../print/created.vue";
import BottomHeader from "./_internal/bottom-header.vue";
import TopHeader from "./_internal/top-header.vue";
import MobileHeader from "@/shared/layout/components/header/_internal/mobile-header.vue";

const breakpoints = useBreakpoints(breakpointsTailwind);
const { logoUrl } = useWhiteLabeling();
const route = useRoute();
//OPUS
const isSignUpPage = computed<boolean>(() => route.name === "SignUp" || route.name === "SignIn");
//!OPUS
// For optimization on mobile devices
const isMobile = breakpoints.smaller("lg");
</script>
