import { useAuth } from "@/core/composables/useAuth";

const { headers } = useAuth();
export async function generateLinkAndDownloadFile(fileUrl: string, fileName: string) {
  try {
    const response = await fetch(fileUrl, {
      method: "GET",
      headers: {
        Authorization: headers.value?.Authorization || "",
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Request error:", response?.statusText);
    }
  } catch (error) {
    console.error("Request error:", error);
  }
}

export function getDateWithoutTime(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
