<template>
  <ul class="mt-4 flex grow flex-col gap-y-2">
    <template v-for="item in items" :key="item.title">
      <li v-if="canShowItem(item)">
        <template v-if="item.id && customLinkComponents[item.id]">
          <component
            :is="item.id && customLinkComponents[item.id]"
            :item="item"
            @close="$emit('close')"
            @select-item="$emit('selectItem', item)"
          />
        </template>
        <MobileMenuLink
          v-else
          :link="item"
          class="py-1 text-lg"
          @close="$emit('close')"
          @select="$emit('selectItem', item)"
        >
          {{ item.title }}
        </MobileMenuLink>
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useOpus } from "@/core/composables";
import { OpusPermissions } from "@/core/enums";
import { useUser } from "@/shared/account";
import { useCustomMobileMenuLinkComponents } from "@/shared/layout/composables/useCustomMobileMenuLinkComponents";
import type { ExtendedMenuLinkType } from "@/core/types";
import MobileMenuLink from "@/shared/layout/components/header/_internal/mobile-menu/mobile-menu-link.vue";
defineEmits<IEmits>();

defineProps<IProps>();

const { customLinkComponents } = useCustomMobileMenuLinkComponents();

interface IProps {
  items: ExtendedMenuLinkType[];
}

interface IEmits {
  (event: "close"): void;
  (event: "selectItem", item: ExtendedMenuLinkType): void;
}

// OPUS
const { isCorporateMember, checkPermissions } = useUser();
const { taxCertificatesStorageEnabled } = useOpus();

const userCanApproveOrders = computed(() => checkPermissions(OpusPermissions.CanApproveOrders));

function canShowItem(item: ExtendedMenuLinkType) {
  if (item.id === "orders_for_approval" && !userCanApproveOrders.value) {
    return false;
  } else if (
    (item.id === "supplier_management" ||
      item.id === "company_info" ||
      item.id === "company_members" ||
      item.id === "account") &&
    !isCorporateMember.value
  ) {
    return false;
  } else if (item.id === "documents" && !taxCertificatesStorageEnabled.value) {
    return false;
  } else {
    return true;
  }
}
// !OPUS
</script>
