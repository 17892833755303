//OPUS
export function replaceUnicode(str: string | undefined, removeUnicode?: boolean) {
  if (str) {
    if (removeUnicode) {
      return str.replace(/[\u0080-\uffff]/g, "");
    } else {
      return str.replace(/[\u0080-\uffff]/g, (match: string) => `&#${match.charCodeAt(0)};`);
    }
  }
}
//!OPUS
