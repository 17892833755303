import { DEFAULT_PAGE_SIZE } from "@/core/constants";
import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import searchProductsOffersQueryDocument from "./searchProductsOffersQuery.graphql";
import type { OpusProductConnection, Query, QueryProductsArgs } from "@/core/api/graphql/types";
import type { ProductsSearchParamsType } from "@/shared/catalog";

export async function searchProductsOffers(
  {
    itemsPerPage = DEFAULT_PAGE_SIZE,
    page = 1,
    categoryId,
    filter,
    sort,
    keyword,
    fuzzy,
    fuzzyLevel,
    productIds,
  }: Partial<ProductsSearchParamsType>,
  /** @default false */
  withZeroPrice?: boolean,
): Promise<OpusProductConnection> {
  const { storeId, catalogId, userId, cultureName, currencyCode } = globals;

  const filterString = [
    `category.subtree:${catalogId}${categoryId ? "/" + categoryId : ""}`,
    withZeroPrice ? "" : "price:(0 TO)",
    filter,
  ]
    .filter(Boolean)
    .join(" ");

  const { data } = await graphqlClient.query<Required<Pick<Query, "products">>, QueryProductsArgs>({
    query: searchProductsOffersQueryDocument,
    variables: {
      storeId,
      userId,
      cultureName,
      currencyCode,
      sort,
      fuzzy,
      fuzzyLevel,
      query: keyword,
      filter: filterString,
      first: itemsPerPage,
      after: String((page - 1) * itemsPerPage),
      productIds,
    },
  });

  return data.products;
}
