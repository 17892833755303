import type { InputQuoteAddressType, OpusContactType, SupplierType } from "@/core/api/graphql/types";
import type { OpusDisplayedAgencyUserType } from "@/shared/company";

export enum RequestType {
  Services = "services",
  BulkQuote = "bulkQuote",
  ProductInquiry = "productInquiry",
  SalesSupport = "salesSupport",
  W9 = "Request W-9",
}

export interface ITemsAndNotesPayload {
  notes: string;
  items: ShortProductType[];
}

export type ShortProductType = {
  name?: string;
  sku?: string;
  quantity: number;
  removable?: boolean;
};

export type ShortContactType = {
  firstName: string;
  lastName: string;
  name?: string;
  email?: string;
  id?: string;
  phone?: string;
};

export type QCHistoryFilterDataType = {
  purposes: string[];
  startDate?: string;
  endDate?: string;
  suppliers: SupplierType[];
  buyers?: OpusContactType[];
};

export type QCHistoryFilterChipsItemType = {
  fieldName: keyof QCHistoryFilterDataType;
  value?: string;
  label: string;
};

export type OpQuickConnectExportType = {
  Id: string;
  Date?: string;
  BuyerName?: string;
  SupplierName: string;
  Description?: string;
  ContactPurpose?: string;
  Comment?: string;
  SupplierCategory?: string;
};

export type QCSupplierSearchFilterDataType = {
  categories: string[];
  suppliers: SupplierType[];
  query: string;
};

export interface IQuickConnectDetails {
  purpose: RequestType;
  existingRequestType?: RequestType;
  services?: ShortProductType[];
  shortProducts?: ShortProductType[];
  attachedFiles?: FileType[];
  selectedAddress?: InputQuoteAddressType;
  existingContact?: ShortContactType;
  contact?: ShortContactType;
  existingRequestComment?: string;
  notes?: string;
  createdDate: Date;
  firstName?: string;
  lastName?: string;
}

export interface IQuickConnectPreparedItem {
  requestType: RequestType;
  notes: string;
  selectedAddress: InputQuoteAddressType;
  selectedSuppliers: SupplierType[];
  contact: ShortContactType;
  uploadedFiles: FileType[];
}
