import { graphqlClient } from "@/core/api/graphql/client";
import confirmSupplierOrderMutationDocument from "./confirmSupplierOrder.graphql";
import type { InputConfirmSupplierOrderType, Mutations, ResultType } from "@/core/api/graphql/types";
import type { MutationsConfirmSupplierOrderArgs } from "@/core/api/graphql/types";

export async function confirmSupplierOrder(payload: InputConfirmSupplierOrderType): Promise<ResultType> {
  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "confirmSupplierOrder">>,
    MutationsConfirmSupplierOrderArgs
  >({
    mutation: confirmSupplierOrderMutationDocument,
    variables: {
      command: payload,
    },
  });

  return data!.confirmSupplierOrder;
}
