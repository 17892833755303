import { graphqlClient } from "../../../client";
import mutationDocument from "./requestSetup.graphql";
import type {
  ResultType,
  InputRequestSetupCommand,
  Mutations,
  MutationsRequestSetupArgs,
} from "@/core/api/graphql/types";

export async function requestSetup(payload: InputRequestSetupCommand): Promise<ResultType> {
  const { data } = await graphqlClient.mutate<Required<Pick<Mutations, "requestSetup">>, MutationsRequestSetupArgs>({
    mutation: mutationDocument,
    variables: {
      command: payload,
    },
  });

  return data!.requestSetup;
}
