import { computed, ref, shallowRef } from "vue";
import { useI18n } from "vue-i18n";
import { toEndDateFilterValue, toStartDateFilterValue } from "@/core/utilities";
import type { QCHistoryFilterChipsItemType, QCHistoryFilterDataType } from "../types";
import type { Ref } from "vue";

const historyFilterData: Ref<QCHistoryFilterDataType> = ref({ purposes: [], suppliers: [], buyers: [] });
const appliedFilterData: Ref<QCHistoryFilterDataType> = ref({ ...historyFilterData.value });

export function opUseQuickConnectFilters() {
  const { d, t } = useI18n();

  const isHistoryFilterEmpty = computed(() => {
    return (
      !appliedFilterData.value?.purposes.length &&
      !appliedFilterData.value?.suppliers.length &&
      !appliedFilterData.value?.buyers?.length &&
      !appliedFilterData.value?.startDate &&
      !appliedFilterData.value?.endDate
    );
  });

  const isFilterDirty = computed(() => {
    const historyDataString = JSON.stringify(historyFilterData.value);
    const appliedDataString = JSON.stringify(appliedFilterData.value);

    return historyDataString !== appliedDataString;
  });

  const filterChipsItems = computed(() => {
    const items: QCHistoryFilterChipsItemType[] = [];

    // Purposes chip
    if (appliedFilterData.value.purposes.length) {
      for (const purpose of appliedFilterData.value.purposes) {
        items.push({ fieldName: "purposes", value: purpose, label: purpose });
      }
    }

    // Suppliers filter
    if (appliedFilterData.value.suppliers.length) {
      for (const supplier of appliedFilterData.value.suppliers) {
        items.push({ fieldName: "suppliers", value: supplier.id, label: supplier.name });
      }
    }

    // Buyers filter
    if (appliedFilterData.value.buyers?.length) {
      for (const buyer of appliedFilterData.value.buyers) {
        items.push({ fieldName: "buyers", value: buyer.id, label: buyer.name! });
      }
    }

    // Dates filter
    const startDateFilterValue = toStartDateFilterValue(appliedFilterData.value.startDate);
    const endDateFilterValue = toEndDateFilterValue(appliedFilterData.value.endDate);
    if (startDateFilterValue) {
      const formattedDate = d(startDateFilterValue);
      items.push({
        fieldName: "startDate",
        value: appliedFilterData.value.startDate,
        label: t("common.labels.created_from", [formattedDate]),
      });
    }
    if (endDateFilterValue) {
      const formattedDate = d(endDateFilterValue);
      items.push({
        fieldName: "endDate",
        value: appliedFilterData.value.endDate,
        label: t("common.labels.created_to", [formattedDate]),
      });
    }

    return items;
  });

  function removeFilterChipsItem(item: QCHistoryFilterChipsItemType) {
    if (item.fieldName === "purposes") {
      appliedFilterData.value.purposes.splice(appliedFilterData.value.purposes.indexOf(item.value as string), 1);
    }

    if (item.fieldName === "suppliers") {
      appliedFilterData.value.suppliers.splice(
        appliedFilterData.value.suppliers.findIndex((_) => _.id === item.value),
        1,
      );
    }

    if (item.fieldName === "buyers") {
      appliedFilterData.value.buyers?.splice(
        appliedFilterData.value.buyers?.findIndex((_) => _.id === item.value),
        1,
      );
    }

    if (item.fieldName === "startDate") {
      appliedFilterData.value.startDate = undefined;
    }

    if (item.fieldName === "endDate") {
      appliedFilterData.value.endDate = undefined;
    }

    historyFilterData.value = { ...appliedFilterData.value };
  }

  function applyFilters() {
    if (JSON.stringify(appliedFilterData.value) === JSON.stringify(historyFilterData.value)) {
      return;
    }
    appliedFilterData.value = { ...historyFilterData.value };
  }

  function resetFilters() {
    historyFilterData.value = { purposes: [], suppliers: [], buyers: [] };
    appliedFilterData.value = { ...historyFilterData.value };
  }

  function resetDataToApplied() {
    historyFilterData.value = { ...appliedFilterData.value };
  }

  return {
    historyFilterData,
    appliedFilterData: computed(() => appliedFilterData.value),
    isHistoryFilterEmpty,
    isFilterDirty,
    filterChipsItems,
    applyFilters,
    resetFilters,
    resetDataToApplied,
    removeFilterChipsItem,
  };
}
