import { globals } from "@/core/globals";
import { graphqlClient } from "../../../../client";
import opGetQuickConnectOrganizationHistoryQueryDocument from "./opusGetQuickConnectOrganizationHistoryQuery.graphql";
import type { Query, OpusQuoteConnection, QueryOrganizationQuotesArgs } from "@/core/api/graphql/types";

export async function opGetQuickConnectOrganizationHistory(
  payload?: QueryOrganizationQuotesArgs,
): Promise<OpusQuoteConnection> {
  const { storeId, userId, cultureName, currencyCode } = globals;
  const { data } = await graphqlClient.query<Required<Pick<Query, "organizationQuotes">>, QueryOrganizationQuotesArgs>({
    query: opGetQuickConnectOrganizationHistoryQueryDocument,
    variables: {
      storeId,
      cultureName,
      currencyCode,
      ...payload,
    },
  });

  return data.organizationQuotes;
}
