import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import getAgenciesSuppliersQuery from "./getAgenciesSuppliersQuery.graphql";
import type { SupplierType, Query, QuerySupplierAgenciesArgs } from "@/core/api/graphql/types";

export async function getAgenciesSuppliers(
  payload: Omit<QuerySupplierAgenciesArgs, "storeId" | "userId">,
): Promise<SupplierType[]> {
  const { storeId, userId } = globals;

  const { data } = await graphqlClient.query<Required<Pick<Query, "supplierAgencies">>, QuerySupplierAgenciesArgs>({
    query: getAgenciesSuppliersQuery,
    variables: {
      storeId,
      userId,
      ...payload,
    },
  });

  return data.supplierAgencies.items?.map((item) => item.supplier) || [];
}
