import { omit } from "lodash";
import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import validateAddressDocument from "./validateAddressQuery.graphql";
import type { AddressValidationResultType, InputAddressType, Query, QueryValidateAddressArgs } from "../../../types";
import type { AnyAddressType } from "@/core/types/addresses";

export async function validateAddress(address: AnyAddressType): Promise<AddressValidationResultType> {
  const { storeId, userId } = globals;

  const { data } = await graphqlClient.query<Required<Pick<Query, "validateAddress">>, QueryValidateAddressArgs>({
    query: validateAddressDocument,
    variables: {
      address: omit(address, ["isDefault"]),
      storeId,
      customerId: userId,
    },
  });

  return data.validateAddress;
}
