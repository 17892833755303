import { computed, ref, shallowRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import {
  addOrUpdateOrderPayment,
  changeOrderApprovalStatus as _changeOrderApprovalStatus,
  getOrder,
} from "@/core/api/graphql";
import { GetOrderFeldsType } from "@/core/api/graphql/orders/queries/getOrder";
import { useGoogleAnalytics } from "@/core/composables/useGoogleAnalytics";
import { ProductType } from "@/core/enums";
import { groupByVendor, Logger } from "@/core/utilities";
import { useNotifications } from "@/shared/notification";
import { OpusApprovalOrderStatuses, useUserOrdersForApproval } from "@/shared/opus";
import type { GetOrderPayloadType } from "@/core/api/graphql/orders/queries/getOrder";
import type {
  OpusCustomerOrderType,
  InputAddOrUpdateOrderPaymentType,
  OrderAddressType,
  OrderLineItemType,
  OpusOrderShipmentType,
  PaymentInType,
  OpusInputChangeOrderApprovalRequestStatusType,
} from "@/core/api/graphql/types";
import type { VendorGroupType } from "@/core/types";

// OPUS
const ga = useGoogleAnalytics();
//!OPUS
const loading = ref(false);
const order = shallowRef<OpusCustomerOrderType | null>(null);
const originalOrder = shallowRef<OpusCustomerOrderType | null>(null);

const giftItems = computed<OrderLineItemType[]>(() => (order.value?.items || []).filter((item) => item.isGift));
const orderItems = computed<OrderLineItemType[]>(() => (order.value?.items || []).filter((item) => !item.isGift));
const orderItemsGroupedByVendor = computed<VendorGroupType<OrderLineItemType>[]>(() => groupByVendor(orderItems.value));
const allItemsAreDigital = computed<boolean>(
  () => !!order.value?.items?.every((item) => item.productType === ProductType.Digital),
);
const shipment = computed<OpusOrderShipmentType | undefined>(() => order.value?.shipments?.[0]);
const payment = computed<PaymentInType | undefined>(() => order.value?.inPayments?.[0]);
const deliveryAddress = computed<OrderAddressType | undefined>(() => shipment.value?.deliveryAddress);
const billingAddress = computed<OrderAddressType | undefined>(() => payment.value?.billingAddress);

// OPUS
const shipments = computed<OpusOrderShipmentType[] | undefined>(() => order.value?.shipments);
const payments = computed<PaymentInType[] | undefined>(() => order.value?.inPayments);
const opusSupplierOrders = computed<OpusCustomerOrderType[] | undefined>(() =>
  order.value?.supplierOrders?.sort((a, b) => a.vendor!.name.localeCompare(b.vendor!.name)),
);
const originalSupplierOrders = computed<OpusCustomerOrderType[] | undefined>(() =>
  originalOrder.value?.supplierOrders?.sort((a, b) => a.vendor!.name.localeCompare(b.vendor!.name)),
);
// !OPUS

export function useUserOrder() {
  // OPUS
  const notifications = useNotifications();
  const { t } = useI18n();
  const { updateNotificationsCount } = useUserOrdersForApproval();
  const router = useRouter();
  // !OPUS
  async function fetchShortOrder(payload: GetOrderPayloadType) {
    loading.value = true;

    try {
      order.value = await getOrder(payload, { fields: GetOrderFeldsType.Short });
    } catch (e) {
      Logger.error(`${useUserOrder.name}.${fetchShortOrder.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchFullOrder(payload: GetOrderPayloadType) {
    loading.value = true;

    try {
      order.value = await getOrder(payload);

      if (order.value.isPrototype && order.value.approvalRequest?.id) {
        void router.replace({ name: "OrderDetails", params: { orderId: order.value.approvalRequest?.id } });
      }

      if (!order.value.isPrototype && order.value.approvalRequest?.prototypeId) {
        await fetchOriginalOrder({ id: order.value.approvalRequest?.prototypeId });
      }
    } catch (e) {
      Logger.error(`${useUserOrder.name}.${fetchFullOrder.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  function clearOrder() {
    order.value = null;
    originalOrder.value = null;
  }

  async function addOrUpdatePayment(payload: InputAddOrUpdateOrderPaymentType, reloadOrder = true) {
    loading.value = true;

    try {
      await addOrUpdateOrderPayment(payload);
    } catch (e) {
      Logger.error(`${useUserOrder.name}.${addOrUpdatePayment.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }

    if (reloadOrder) {
      await fetchFullOrder({ id: payload.orderId });
    }
  }

  async function changeOrderApprovalStatus(
    payload: OpusInputChangeOrderApprovalRequestStatusType,
    orderId: string,
    reloadOrder = true,
  ) {
    loading.value = true;

    try {
      await _changeOrderApprovalStatus(payload);

      notifications.success({
        text:
          payload.newStatus === OpusApprovalOrderStatuses.Approved
            ? t("common.messages.order_approved")
            : t("common.messages.order_rejected"),
        duration: 10000,
        single: true,
      });
    } catch (e) {
      Logger.error(`${useUserOrder.name}.${changeOrderApprovalStatus.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }

    void updateNotificationsCount();
    if (reloadOrder) {
      await fetchFullOrder({ id: orderId });
    }
    // OPUS
    if (order.value && payload.newStatus === OpusApprovalOrderStatuses.Approved) {
      ga.purchase(order.value, order.value?.id, "manually_approved");
    }
    // !OPUS
  }

  async function fetchOriginalOrder(payload: GetOrderPayloadType) {
    try {
      originalOrder.value = await getOrder(payload);
    } catch (e) {
      Logger.error(`${useUserOrder.name}.${fetchOriginalOrder.name}`, e);
      throw e;
    }
  }

  return {
    loading: computed(() => loading.value),
    order: computed(() => order.value),
    allItemsAreDigital,
    giftItems,
    orderItems,
    orderItemsGroupedByVendor,
    deliveryAddress,
    billingAddress,
    shipment,
    payment,
    fetchShortOrder,
    fetchFullOrder,
    clearOrder,
    addOrUpdatePayment,
    // OPUS
    opusSupplierOrders,
    shipments,
    payments,
    changeOrderApprovalStatus,
    originalOrder: computed(() => originalOrder.value),
    originalSupplierOrders,
    // !OPUS
  };
}
