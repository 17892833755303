import type { RouteRecordRaw } from "vue-router";

const Info = () => import("@/pages/company/info.vue");
const Members = () => import("@/pages/company/members.vue");
const SupplierManagement = () => import("@/pages/company/supplier-management.vue");

export const corporateRoutes: RouteRecordRaw[] = [
  { path: "supplier-management", name: "SupplierManagement", component: SupplierManagement },
  { path: "info", name: "CompanyInfo", component: Info },
  { path: "members", name: "CompanyMembers", component: Members },
];
