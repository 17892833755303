import { canUseDOM } from "@apollo/client/utilities";
import { useScriptTag } from "@vueuse/core";
import { IS_DEVELOPMENT } from "@/core/constants";
import { Logger } from "@/core/utilities";
import { useModuleSettings } from "./useModuleSettings";

const DEBUG_PREFIX = "[GTAG]";
const MODULE_ID = "Opus";
const SETTINGS_MAPPING = {
  "Opus.Analytics.GoogleTagManagerEnabled": "enableTracking",
  "Opus.Analytics.GoogleTagManagerId": "measurementId",
};

const { getModuleSettings } = useModuleSettings(MODULE_ID);

type ModuleSettingsType = { enableTracking: boolean; measurementId: string };

export function useGoogleTagManager() {
  function init() {
    if (!canUseDOM) {
      return;
    }

    const { enableTracking, measurementId } = getModuleSettings(SETTINGS_MAPPING) as ModuleSettingsType;

    if (enableTracking && measurementId) {
      if (!IS_DEVELOPMENT) {
        const noscript = document.createElement("noscript");
        noscript.innerHTML = `
          <iframe src="https://www.googletagmanager.com/ns.html?id=${measurementId}"
                  height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `;
        document.body.appendChild(noscript);

        const scriptSrc = `https://www.googletagmanager.com/gtm.js?id=${measurementId}`;
        const { load } = useScriptTag(scriptSrc, undefined, {
          async: true,
          attrs: { id: "google_tag_manager_script" },
        });

        load().catch((err) => {
          Logger.error(DEBUG_PREFIX, "Failed to load Google Tag Manager script:", err);
        });

        Logger.info(DEBUG_PREFIX, `Google Tag Manager initialized with ID: ${measurementId}`);
      } else {
        Logger.debug(DEBUG_PREFIX, "GTM not initialized in development mode");
      }
    }
  }

  return {
    init,
  };
}
