import type { PaymentFilterType } from "../types";

export const TERMS_RELEASE_DATE = "2024-04-15T00:00:00.000000Z";

export const PAYMENT_FILTER_TYPES: PaymentFilterType[] = [
  {
    text: "Account billing",
    value: "Manual",
  },
  {
    text: "Credit card",
    value: "Alternative",
  },
];
