import { graphqlClient } from "../../../client";
import mutationDocument from "./deactivateMemberAddressMutation.graphql";
import type {
  InputDeactivateMemberAddressType,
  Mutations,
  MutationsDeactivateMemberAddressArgs,
} from "@/core/api/graphql/types";

export async function deactivateMemberAddress(payload: InputDeactivateMemberAddressType): Promise<void> {
  await graphqlClient.mutate<
    Required<Pick<Mutations, "deactivateMemberAddress">>,
    MutationsDeactivateMemberAddressArgs
  >({
    mutation: mutationDocument,
    variables: {
      command: {
        addressKey: payload.addressKey,
        memberId: payload.memberId,
      },
    },
  });
}
