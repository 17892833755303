import { graphqlClient } from "@/core/api/graphql/client";
import cancelSupplierOrderMutationDocument from "./cancelSupplierOrder.graphql";
import type {
  InputCancelSupplierOrderType,
  Mutations,
  ResultType,
  MutationsCancelSupplierOrderArgs,
} from "@/core/api/graphql/types";

export async function cancelSupplierOrder(payload: InputCancelSupplierOrderType): Promise<ResultType> {
  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "cancelSupplierOrder">>,
    MutationsCancelSupplierOrderArgs
  >({
    mutation: cancelSupplierOrderMutationDocument,
    variables: {
      command: payload,
    },
  });

  return data!.cancelSupplierOrder;
}
